import React, { useEffect, useState, useCallback } from "react";
import { Box, Typography, Divider, CircularProgress } from "@mui/material";
import { useQuery } from "@apollo/client";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import { GET_CAMPAIGN_BY_ID } from "src/graphql/query";

const CompaignView: React.FC = () => {
  const [list, setList] = useState<[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 0, pageSize: 50 });
  const [total, setTotal] = useState(0);

  const COLUMNS = [
    {
      headerName: "Stock Name",
      field: "name",
      width: 200,
      renderCell: ({ row }: any) => (
        <Typography variant="body2">{row.name || "N/A"}</Typography>
      ),
    },
    {
      headerName: "In Stock Quantity",
      field: "quantity",
      width: 200,
      renderCell: ({ row }: any) => (
        <Typography variant="body2">{row.quantity}</Typography>
      ),
    },
    {
      headerName: "Used Stock",
      field: "usedStock",
      width: 200,
      renderCell: ({ row }: any) => (
        <Typography variant="body2">{row.usedStock}</Typography>
      ),
    },
    {
      headerName: "Remaining Stock",
      field: "remainingStock",
      width: 200,
      renderCell: ({ row }: any) => (
        <Typography variant="body2">{row.remainingStock}</Typography>
      ),
    },
  ];

  const id = window.location.pathname.split("/")[2];
  const { data, loading, error, refetch } = useQuery(GET_CAMPAIGN_BY_ID, {
    variables: { _id: id },
  });

  useEffect(() => {
    if (data?.getCampaign?.data?.stock) {
      const flattenedStock = data.getCampaign.data.stock.flatMap(
        (item: any, index: number) =>
          (item.inStock || []).map((stockItem: any, stockIndex: number) => ({
            id: `${item._id || `stock-${index}`}-${stockIndex}`,
            stockId: item._id,
            name: stockItem.name,
            quantity: stockItem.quantity,
            totalStock: item.total?.inStockCount || 0,
            remainingStock: item.total?.remainingStockCount || 0,
            usedStock: item.total?.usedStockCount || 0,
          }))
      );

      setList(flattenedStock);
      setTotal(flattenedStock.length);
    }
  }, [data]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography color="error">Error: {error.message}</Typography>
      </Box>
    );
  }

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page,
      limit: newPageSize,
    });
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  const getRowId = (row: any) => row._id || `row-${Math.random()}`;
  return (
    <Box p={2}>
      {!openModal && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            paddingRight={4}
          >
            <Typography variant="h4" style={{ color: "#00C5B9" }}>
              Compign Information
            </Typography>
          </Box>
          <Divider />
          <Box mt={2}>
            <CustomTable
              columns={COLUMNS}
              data={list}
              getRowId={getRowId}
              paginationModel={{
                page: pagination.page,
                pageSize: pagination.pageSize,
              }}
              totalCount={total}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default CompaignView;
