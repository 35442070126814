import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query GetUsers(
    $page: Int!
    $limit: Int!
    $search: String
    $role: String
    $filter: String
    $platFrom: String
    $deletedAt: Boolean
  ) {
    getUsers(
      page: $page
      limit: $limit
      search: $search
      role: $role
      filter: $filter
      platFrom: $platFrom
      deletedAt: $deletedAt
    ) {
      message
      statusCode
      count
      data {
        _id
        firstName
        lastName
        email
        coverImage
        profileImage
        mobileNumber
        role
        profession
        pinCode
        verified
        isVerified
        isBlocked
        deletedAt
        # platFrom
        googleId
        appleId
        createdAt
      }
    }
  }
`;

export const GET_EVENTS = gql`
  query GetEventsAll(
    $page: Int
    $limit: Int
    $search: TString
    $category: [TString]
    $dateFilter: TString
    $priceFilter: TString
  ) {
    getEventsAll(
      page: $page
      limit: $limit
      search: $search
      category: $category
      dateFilter: $dateFilter
      priceFilter: $priceFilter
    ) {
      message
      statusCode
      count
      totalEvents
      data {
        _id
        title
        images
        eventBy
        description
        startTime
        endTime
        showOnWeb
        sourceType
        price
        venue
        startDate
        endDate
        language
        artist
        category
        status
        verifiedBy
        verificationDate
        desktopImage
        mobileImage
        rank
        isPromoted 
        address {
          address_1
          address_2
          pinCode
          location
          landmark
        }
      }
    }
  }
`;

// events detail
export const GET_EVENTS_BY_ID = gql`
  query GetEvent($_id: ID!) {
    getEvent(_id: $_id) {
      message
      statusCode
      data {
        _id
        title
        images
        eventBy
        description
        startTime
        endTime
        showOnWeb
        sourceType
        price
        type
        venue
        startDate
        endDate
        language
        artist
        category
        status
        verifiedBy
        verificationDate
        reason
        bookingPartner
        desktopImage
        mobileImage
        rank
        isPromoted
        othersTimings
        pincode
        code
        slug
        metaTitle
        metaDescription
        focus
        address {
          address_1
          address_2
          pinCode
          location
          landmark
        }
        contactDetails {
          name
          mobileNumber
        }
      }
    }
  }
`;

export const GET_CAMPAIGNS = gql`
  query GetCampaigns($page: Int, $limit: Int, $search: String) {
    getCampaigns(page: $page, limit: $limit, search: $search) {
      message
      statusCode
      count
      data {
        _id
        name
        url
        qrCode
        count
        utmCampaign
        utmSource
        slug
          pricing {
          name
          price
          description
          km
          image
        }
         stock {
                inStock {
                    name
                    quantity
                }
                  remainingStock {
                    name
                    quantity
                }
                total {
                    inStockCount
                    remainingStockCount
                    usedStockCount
                }
                     usedStock {
                    name
                    quantity
                }
                   
            }
       
}
    }
  }
`;

export const GET_CAMPAIGN_BY_ID = gql`
query GetCampaign($_id: ID!) {
    getCampaign(_id: $_id) {
      message
      statusCode
      data {
        _id
        name
        qrCode
        url
        count
        utmCampaign
        utmSource
        slug
        pricing {
          name
          price
          description
          km
          image
        }
          stock {
                inStock {
                    name
                    quantity
                }
                 usedStock {
                    name
                    quantity
                }
                      remainingStock {
                    name
                    quantity
                }
                total {
                    inStockCount
                    remainingStockCount
                    usedStockCount
                }  
            }
      }
    }
  }
`;

export const GET_PINCODES = gql`
  query GetPinCodes($page: Int, $limit: Int, $search: String) {
    getPinCodes(page: $page, limit: $limit, search: $search) {
      message
      statusCode
      count
      data {
        _id
        areaName
        pinCode
        rank
      }
    }
  }
`;
export const GET_ALL_PINCODES = gql`
  query GetAllPinCodes {
    getAllPinCodes {
      message
      statusCode
      count
      data {
        _id
        areaName
        pinCode
        metaTitle
        metaDescription
        metaKeywords
        imageUrl
      }
    }
  }
`;

export const GET_CATEGORY = gql`
  query GetCategories($page: Int, $limit: Int, $search: String) {
    getCategories(page: $page, limit: $limit, search: $search) {
      message
      statusCode
      count
      data {
        _id
        categoryName
        subCategoryCount
        rank
        description
        imageUrl
        subCategory {
          subCategoryName
          imageUrl
          description
          _id
        }
      }
    }
  }
`;

export const GET_SUB_CATEGORY = gql`
  query GetCategoryByIdWithPaginatedSubcategories(
    $_id: ID!
    $subCategoryPage: Int
    $subCategoryLimit: Int
    $search: String
  ) {
    getCategoryByIdWithPaginatedSubcategories(
      subCategoryPage: $subCategoryPage
      subCategoryLimit: $subCategoryLimit
      search: $search
      _id: $_id
    ) {
      data {
        _id
        categoryName
        businessProfileCount
        subCategoryCount
        imageUrl
        subCategory {
          _id
          subCategoryName
          description
          rank
          metaTitle
          metaDescription
          metaKeywords
          overviews
          imageUrl
        }
      }
    }
  }
`;

export const GET_SUB_CATEGORIES = gql`
  query GetCategory($_id: ID!) {
    getCategory(_id: $_id) {
      message
      statusCode
      data {
        _id
        categoryName

        subCategory {
          _id
          subCategoryName
        }
      }
    }
  }
`;
export const GET_NEWS = gql`
  query GetNews(
    $page: Int
    $limit: Int
    $search: String
    $category: NewsCategory
  ) {
    getNews(page: $page, limit: $limit, search: $search, category: $category) {
      message
      statusCode
      count
      news {
        _id
        title
        image
        shortDescription
        longDescription
        date
        category
        sourceType
        sourceId
        clickCount
      }
    }
  }
`;
export const GET_FAQS = gql`
  query GetFaqs($page: Int, $limit: Int, $search: String, $filter: String) {
    getFaqs(page: $page, limit: $limit, search: $search, filter: $filter) {
      message
      statusCode
      count
      data {
        _id

        question
        answer
      }
    }
  }
`;

export const GET_BLOGS = gql`
  query GetBlogsAll($page: Int, $limit: Int, $search: String) {
    getBlogsAll(page: $page, limit: $limit, search: $search) {
      message
      statusCode
      count
      data {
        _id
        title
        image
        rank
        publishedDate
        publishedBy
        description
        createdBy
        status
      }
    }
  }
`;
export const GET_DEALS = gql`
  query GetDeals(
    $page: Int
    $limit: Int
    $search: String
    $selectedCategory: [TString]
    $filter: DealsFilter
  ) {
    getDeals(
      page: $page
      limit: $limit
      search: $search
      selectedCategory: $selectedCategory
      filter: $filter
    ) {
      message
      statusCode
      selectedLocation
      selectedCategory
      selectedDiscountBracket
      selectedDiscountPercentage
      totalDealsCount
      data {
        _id
        businessProfileId
        title
        isPromoted
        image
        category
        subCategoryId
        area
        offer
        offerType
        description
        termsAndConditions
      }
    }
  }
`;

export const GET_SUB_CATEGORY_DATA = gql`
  query GetRelatedDataBySubCategory($subcategoryId: ID!, $pincode: String) {
    getRelatedDataBySubCategory(
      subcategoryId: $subcategoryId
      pincode: $pincode
    ) {
      subCategoryCount
      message
      statusCode
      topTenBusiness {
        _id
        businessProfileId
        subCategoryId
        rank
        title
        description
        pinCodeId {
          _id
          areaName
          pinCode
        }
        startDate
        endDate
      }
      Data {
        _id
        subCategoryName
        description
        metaTitle
        metaDescription
        metaKeywords
        subCategoryCount
      }
      topThreeBusiness {
        _id
        businessProfileId
        subCategoryId
        type
        rank
        title
        description
        pinCodeId {
          _id
          areaName
          pinCode
        }
        startDate
        endDate
        businessProfile {
          _id
          businessName
        }
      }
      faq {
        _id
        businessProfileId
        subCategoryId
        question
        answer
      }
      tags {
        _id
        key
        value
        subCategoryId
      }
    }
  }
`;

export const GET_BUSINESS_DATA = gql`
  query GetBusinessProfiles($primaryCategory: [String], $search: String) {
    getBusinessProfiles(primaryCategory: $primaryCategory, search: $search) {
      message
      statusCode
      data {
        _id
        userId
        businessName
        tag
        brandName
        brandLogo
        website
        businessEmail
        documents
        managedBy
        mobileNumber
        bgImage
        status
        reason
        verifiedBy
        verificationDate
        totalReviewsCount
        averageRating
        claimBusiness
        socialMedia {
          platform
          handle
        }
      }
    }
  }
`;

export const GET_SUBCATEGORY_BLOGS = gql`
  query GetBlogs($subCategoryId: ID) {
    getBlogs(subCategoryId: $subCategoryId) {
      message
      statusCode
      count
      data {
        _id
        title
        image
        publishedDate
        publishedBy
        description
        createdBy
        subCategoryId
        blogTitleReadingTime
        blogDescriptionReadingTime
      }
    }
  }
`;


export const GET_BUSINESS_PROFILES_BY_DATE = gql`
query GetBusinessProfilesByDate($startDate: String!, $endDate: String!, $page: Int!, $limit: Int!, $search: String) {
  getBusinessProfilesAllByDate(page: $page, limit: $limit, startDate: $startDate, endDate: $endDate,  search: $search) {
    message
    statusCode
    count
    data {
      _id
      userId
      UserName
      businessName
      tag
      brandName
      brandLogo
      website
      businessEmail
      documents
      managedBy
      mobileNumber
      bgImage
      status
      reason
      verifiedBy
      verificationDate
      metaTitle
      focus
      totalReviewsCount
      averageRating
      deleteFlag
      claimBusiness
      claimUserMobileNo
      gstinNumber
      proofDocument
      yearOfEst
      googleBusinessStatus
      isstatusVerified
      metaDescription
      metaKeywords
      subTitle
      placeId
      claimStatus
      pricing
      menuUrl
      mobileNumber2
      reviewCount
      updateStatusVerified
      classOfbusiness
      rank
      ranking
      totalClicks
      pinCodeDetails
      primarySubcategoryName
      code
      slug
      tagsValue
      additionalSubCategoriesName
      image
      video
      area
    }
  }
}
`;



export const GET_APPROVED_BUSINESS = gql`
  query GetBusinessProfiles(
    $page: Int
    $limit: Int
    $brandFilter: String
    $pinCode: [String]
    $primaryCategory: [String]
    $search: String
    $sortOption: SortOption
  ) {
    getBusinessProfiles(
      page: $page
      limit: $limit
      brandFilter: $brandFilter
      pinCode: $pinCode
      primaryCategory: $primaryCategory
      search: $search
      sortOption: $sortOption
    ) {
      message
      statusCode
      count
      data {
        _id
        businessName
        averageRating
        code
        slug
        bgImage
        pinCodeDetails
        primarySubcategoryName
        image
        additionalSubCategoriesName
      }
      pinCode {
        areaName
      }
      subCategory {
        subCategoryName
      }
    }
  }
`;
export const GET_CONTACT_US = gql`
  query GetContactUs($limit: Int, $page: Int, $search: String) {
    getContactUs(limit: $limit, page: $page, search: $search) {
      statusCode
      count
      data {
        _id
        userId
        name
        email
        phoneNumber
        description
        createdAt
        statusHistory {
          status
          adminComment
          updatedAt
        }
        user {
          _id
          firstName
          lastName
          email
          mobileNumber
          role
        }
      }
    }
  }
`;

export const GET_TAGS = gql`
  query GetTags($page: Int, $limit: Int, $search: String) {
    getTags(page: $page, limit: $limit, search: $search) {
      message
      statusCode
      count
      data {
        _id
        key
        value
        subCategoryId
      }
    }
  }
`;

export const GET_REVIEWS = gql`
  query GetReviews($search: String, $limit: Int, $page: Int) {
    getReviews(search: $search, limit: $limit, page: $page) {
      message
      statusCode
      count
      data {
        _id
        businessProfileId
        categoryName
        ratings
        title
        description
        authorName
        authorPosition
        profileImage
        city
        createdAt
      }
    }
  }
`;

export const GET_HOME_API_DATA = gql`
  query GetHomePageAPI($categoryType: CategoryType) {
    getHomePageAPI(categoryType: $categoryType) {
      savedCategories {
        _id
        rank
        categories {
          _id
          categoryName
        }
        subCategory {
          _id
          subCategoryName
          categoryId
          metaTitle
          metaDescription
          metaKeywords
          category {
            _id
            categoryName
          }
        }
      }
    }
  }
`;

export const GET_POPULAR_LOCATIONS = gql`
  query GetPopularLocations(
    $category: String
    $pinCode: ID
    $search: TString
    $page: Int
    $limit: Int
  ) {
    getPopularLocations(
      category: $category
      pinCode: $pinCode
      search: $search
      page: $page
      limit: $limit
    ) {
      message
      statusCode
      count
      data {
        _id
        title
        images
        address
        google_location_link
        about
        category
        metaTitle
        metaDescription
        metaKeywords
        rank
        isPromoted
        instagram
        youtube 
        facebook
        twitter
        website
        phoneNumber
        amenities
        pincodeDetails {
          _id
          areaName
          pinCode
        }
        timings {
          day
          startTime
          endTime
          other
        }
      }
    }
  }
`;

export const GET_ADVERTISE = gql`
  query GetAdsWithUs {
    getAdsWithUs {
      statusCode
      count
      data {
        _id
        userId
        email
        phoneNumber
        image
        address
        description
        fullName
        companyName
        createdAt
        statusHistory {
          status
          adminComment
          updatedAt
        }
        user {
          _id
          firstName
          lastName
          email
          mobileNumber
          role
        }
      }
    }
  }
`;

export const GET_CATEGORY_DATA = gql`
  query GetCategory($_id: ID!) {
    getCategory(_id: $_id) {
      message
      statusCode
      data {
        _id
        categoryName
        imageUrl
        description
        categoryType
        businessProfileCount
        subCategoryCount
        overviews
        rank
      }
    }
  }
`;

export const GET_POPULAR_LOCATION = gql`
  query GetPopularLocationById($id: ID!) {
    getPopularLocationById(id: $id) {
      message
      statusCode
      data {
        _id
        title
        address
        UserName
        pincodeDetails {
          _id
          areaName
          pinCode
        }
        google_location_link
        images
        about
        category
        rank
        isPromoted
        slug
        metaTitle
        metaDescription
        metaKeywords
        focus
        instagram
        youtube
        facebook
        twitter
        website
        phoneNumber
        amenities
        timings {
          day
          startTime
          endTime
          other
        }
      }
    }
  }
`;

export const GET_BUSINESS_PROFILE = gql`
  query GetBusinessProfilebyslug($slug: TString!) {
    getBusinessProfilebyslug(slug: $slug) {
      message
      statusCode
      userCanEdit
      data {
        galleries {
          _id
          image
          video
        }
        _id
        userId
        UserName
        businessName
        tag
        brandName
        brandLogo
        website
        businessEmail
        documents
        managedBy
        mobileNumber
        bgImage
        totalReviewsCount
        averageRating
        claimBusiness
        dealsCount
        status
        isPromoted 
        reason
        verifiedBy
        verificationDate
        deleteFlag
        yearOfEst
        googleBusinessStatus
        isstatusVerified
        metaDescription
        metaKeywords
        metaTitle
        focus
        subTitle
        placeId
        claimStatus
        pricing
        menuUrl
        mobileNumber2
        reviewCount
        rank
        ranking
        code
        slug
        pinCodeDetails
        primarySubcategoryName
        tagsValue
        additionalSubCategoriesName
        image
        video
        owners {
          designation
          firstName
          lastName
          mobileNumber
          email
        }
        additionalSubCategories {
          _id
          subCategoryName
        }
        images {
          imageUrl
          photoId
          uploadSource
        }
        primarySubCategory {
          _id
          subCategoryName
          code
        }
        overviews {
          about
          dynamicOptions
        }
        images {
          imageUrl
          photoId
          uploadSource
        }
        socialMedia {
          platform
          handle
        }
        members {
          name
          email
          status
        }

        timings {
          timings {
            day
            startTime
            endTime
            others
          }
        }
        address {
          _id
          businessProfileId
          addressses {
            address
            buildingOrShopNumber
            landmark
            neighborhood
            googleMapLink
            pinCode {
              areaName
              pinCode
            }
          }
        }
        faqs {
          question
          answer
        }
        tags {
          key
          value
        }
        deals {
          _id
          businessProfileId
          title
          image
          category
          subCategoryId
          area
          offer
          offerType
          description
          termsAndConditions
          deleted
          activityStatus
          code
          slug
          duration {
            startDate
            endDate
          }
        }
        # relatedBusiness {
        #   count
        #   businessProfile {
        #     _id
        #     businessName
        #     UserName
        #     brandLogo
        #     bgImage
        #     averageRating
        #     code
        #     slug
        #     pinCodeDetails
        #     primarySubcategoryName
        #     images {
        #       imageUrl
        #       photoId
        #       uploadSource
        #     }
        #     timings {
        #       timings {
        #         day
        #         startTime
        #         endTime
        #       }
        #     }
        #     images {
        #       imageUrl
        #       photoId
        #       uploadSource
        #     }
        #   }
        # }
      }
    }
  }
`;

export const GET_NEWS_DATA = gql`
  query GetNewsById($_id: ID!) {
    getNewsById(_id: $_id) {
      message
      statusCode
      news {
        _id
        title
        image
        shortDescription
        longDescription
        date
        category
        sourceType
        sourceId
        shortDescriptionReadingTime
        longDescriptionReadingTime
        clickCount
      }
    }
  }
`;


export const GET_SUBCATEGORY = gql`
  query GetSubCategory($_id: ID) {
    getSubCategory(_id: $_id) {
      message
      statusCode
      data {
        _id
        subCategoryName
        description
        categoryId
        metaTitle
        metaDescription
        metaKeywords
        focus
        subCategoryCount
        additionalSubCategories
        imageUrl
        title
        bannerImages {
          imageUrl
          redirectLink
          Title
          Description
        }
      }
    }
  }
`;
export const GET_CAMPAIGN_USER = gql`
  query GetCampaignUser($_id: ID!) {
    getCampaignUser(_id: $_id) {
      message
      statusCode
      data {
        _id
        name
        phoneNumber
        campaignSlug
      }
    }
  }
`;

export const GET_CAMPAIGN_USERS = gql`
  query GetCampaignUsers(
    $page: Int
    $limit: Int
    $search: String
  ) {
    getCampaignUsers(
      page: $page
      limit: $limit
      search: $search
    ) {
      message
      statusCode
      count
      data {
        _id
        name
        phoneNumber
        campaignSlug
      }
    }
  }
`;



export const GET_TAGS_DATA = gql`
  query GetRelatedDataBySubCategory($subcategoryId: ID!) {
    getRelatedDataBySubCategory(subcategoryId: $subcategoryId) {
      tags {
        _id
        key
        value
        subCategoryId
      }
    }
  }
`;

export const GET_ALL_TRACKING_DATA = gql`
  query GetAllTrackingData(
    $entityType: TString
    $page: Int
    $limit: Int
    $search: String
  ) {
    getAllTrackingData(
      entityType: $entityType
      page: $page
      limit: $limit
      search: $search
    ) {
      count
      data {
        _id
        entityId
        entityType
        actionType
        userId
        timestamp
        entityDetails {
          businessProfile {
            brandLogo
            businessName
          }
          deal {
            title
            image
          }
          blog {
            title
            image
          }
          tag {
            key
          }
          popularLocation {
            title
            images
          }
          event {
            title
            images
          }
        }
      }
    }
  }
`;

export const GET_DEAL = gql`
  query GetDeal($_id: ID!) {
    getDeal(_id: $_id) {
      message
      statusCode
      data {
        _id
        businessProfileId
        title
        isPromoted
        image
        category
        subCategoryId
        area
        offer
        offerType
        description
        termsAndConditions
        deleted
        activityStatus
        clickCount
        code
        slug
        metaTitle
        metaDescription
        focus
        businessProfile {
          businessName
           pinCodeDetails
        }
        repeatDuration {
          repeat
          weekDays
        }
        duration {
          startDate
          endDate
        }
        discountBracket {
          minimum
          maximum
        }
        contactNo
        bookingLinks
        timing{
          name
          time
          price
        }
      }
    }
  }
`;

export const GET_ALL_SUBCATEGORIES = gql`
  query GetAllSubCategories {
    getAllSubCategories {
      message
      statusCode
      count
      data {
        _id
        subCategoryName
        categoryId
      }
    }
  }
`;

export const GET_REGION_DATA = gql`
  query GetPinCodeDetails($_id: ID!, $search: String) {
    getPinCodeDetails(_id: $_id, search: $search) {
      message
      statusCode
      pinCode {
        _id
        areaName
        pinCode
      }
      topBusinesses {
        _id
        businessProfileId
        subCategoryId
        type
        rank
        title
        description
        image
        startDate
        endDate
        businessProfile {
          _id
          userId
          UserName
          businessName
          tag
          brandName
          brandLogo
          website
          businessEmail
          documents
          managedBy
          mobileNumber
          bgImage
          totalReviewsCount
          averageRating
          status
          reason
          verifiedBy
          verificationDate
          deleteFlag
          claimBusiness
          yearOfEst
          socialMedia {
            platform
            handle
          }
          timings {
            _id
            businessProfileId
          }
          address {
            _id
            businessProfileId
          }
          reviews {
            _id
            businessProfileId
            categoryName
            ratings
            image
            video
            title
            description
            authorName
            authorPosition
            profileImage
            city
            createdAt
          }
          members {
            name
            email
            status
          }
        }
        pinCodeId {
          _id
          areaName
          pinCode
        }
      }
      businesses {
        _id
        userId
        UserName
        businessName
        tag
        brandName
        brandLogo
        website
        businessEmail
        documents
        managedBy
        mobileNumber
        bgImage
        status
        reason
        verifiedBy
        verificationDate
        totalReviewsCount
        averageRating
        deleteFlag
        claimBusiness
        yearOfEst
        galleries {
          _id
          image
          video
        }
        timings {
          _id
          businessProfileId
          timings {
            day
            startTime
            endTime
          }
        }
        overviews {
          _id
          businessProfileId
          about
          dynamicOptions
        }
        owners {
          firstName
          lastName
          mobileNumber
          email
        }
        address {
          _id
          businessProfileId
          addressses {
            address
            buildingOrShopNumber
            landmark
            neighborhood
            googleMapLink
          }
        }
      }
      deals {
        _id
        businessProfileId
        title
        image
        category
        subCategoryId
        area
        offer
        offerType
        description
        termsAndConditions
        deleted
        activityStatus
        clickCount
      }
      reviews {
        _id
        businessProfileId
        categoryName
        ratings
        image
        video
        title
        description
        authorName
        authorPosition
        profileImage
        city
        createdAt
      }
      popularLocations {
        _id
        title
        UserName
        images
        address
        google_location_link
        about
        category
        metaTitle
        metaDescription
        metaKeywords
      }
    }
  }
`;

export const GET_PINCODE_DETAIL = gql`
  query GetPinCode($_id: ID!) {
    getPinCode(_id: $_id) {
      message
      statusCode
      data {
        _id
        areaName
        pinCode
        metaTitle
        metaDescription
        metaKeywords
        imageUrl
        coordinates {
          latitude
          longitude
        }
        location {
          type
          coordinates
        }
      }
    }
  }
`;

export const GET_RANKS = gql`
  query GetPopularLocations {
    getPopularLocations {
      message
      statusCode
      count
      data {
        rank
      }
    }
  }
`;
export const LOGIN_ME = gql`
  query LoginMeForAdmin {
    loginMeForAdmin {
      accessToken
      isEnvAdmin
      message
      statusCode
      user {
        _id
        firstName
        lastName
        role
      }
      privacy {
        _id
        createdAt
        updatedAt
        permissions {
          type
          view
          update
          verify
          delete
          add
        }
      }
    }
  }
`;

export const GET_BLOG_BY_ID = gql`
  query GetBlog($_id: ID!) {
    getBlog(_id: $_id) {
      message
      statusCode
      data {
        _id
        title
        image
        publishedDate
        description
        publishedBy
        metaTitle
        metaDescription
        metaKeywords
        focus
        imageAltText
        categoryId {
          _id
          categoryName
        }
      }
    }
  }
`;

export const GET_CLAIM_BUSINESS = gql`
  query GetClaimBusinesses($page: Int, $limit: Int, $search: String) {
    getClaimBusinesses(page: $page, limit: $limit, search: $search) {
      message
      statusCode
      data {
        _id
        userId
        businessName
        businessProfileId
        designation
        firstName
        lastName
        mobileNumber
        email
        verifiedBy
        verificationDate
        claimBusiness
        gstinNumber
        proofDocument
        placeId
        claimStatus
      }
    }
  }
`;
export const GET_DEAL_ENUM = gql`
  query GetDealsEnum($page: Int, $limit: Int, $search: String) {
    getDealsEnum(page: $page, limit: $limit, search: $search) {
      total
      message
      statusCode
      data {
        _id
        name
        description
        rank
      }
    }
  }
`;
export const GET_EVENT_ENUM = gql`
  query GetEventsEnum($page: Int, $limit: Int, $search: String) {
    getEventsEnum(page: $page, limit: $limit, search: $search) {
      total
      message
      statusCode
      data {
        _id
        name
        description
        rank
      }
    }
  }
`;
export const GET_BUSINESS_BY_CODE = gql`
  query GetBusinessProfilebyCode($code: TString!) {
    getBusinessProfilebyCode(code: $code) {
      message
      statusCode
      userCanEdit
      data {
        _id
        overviews {
          otherOptions
          _id
        }
      }
    }
  }
`;
export const GET_CLAIM_BUSINESSES = gql`
  query GetClaimBusinesses($page: Int, $limit: Int, $search: String) {
    getClaimBusinesses(page: $page, limit: $limit, search: $search) {
      message
      statusCode
      count
      data {
        _id
        userId
        businessName
        businessProfileId
        designation
        firstName
        lastName
        mobileNumber
        email
        verifiedBy
        verificationDate
        claimBusiness
        gstinNumber
        proofDocument
        placeId
        claimStatus
        createdAt
      }
    }
  }
`;
export const GET_CONFIG_DATA = gql`
  query GetConfigData($page: Int, $limit: Int, $search: String) {
    getConfigData(page: $page, limit: $limit, search: $search) {
      message
      statusCode
      count
      data {
        _id
        configData
      }
    }
  }
`;
export const GET_POPULAR_LOCATION_ENUM = gql`
  query GetPopularLocationEnum($page: Int, $limit: Int, $search: String) {
    getPopularLocationEnum(page: $page, limit: $limit, search: $search) {
      total
      message
      statusCode
      data {
        _id
        name
        description
        rank
      }
    }
  }
`;

export const GET_EVENTS_ENUM_ALL = gql`
  query GetAllEventsEnum {
    getAllEventsEnum {
      total
      message
      statusCode
      data {
        _id
        name
        description
        rank
      }
    }
  }
`;
export const GET_LOCATION_ENUM_ALL = gql`
  query GetAllPopularLocationEnum {
    getAllPopularLocationEnum {
      total
      message
      statusCode
      data {
        _id
        name
        description
        rank
      }
    }
  }
`;

export const GET_DEALS_ENUM_ALL = gql`
  query GetAllDealsEnum {
    getAllDealsEnum {
      total
      message
      statusCode
      data {
        _id
        name
        description
        rank
      }
    }
  }
`;

export const GET_ALL_USERS = gql`
  query GetAllUsers($role: String) {
    getAllUsers(role: $role) {
      message
      statusCode
      count
      data {
        _id
        firstName
        lastName
        email
      }
    }
  }
`;
export const GET_ALL_PRIVACY = gql`
  query GetAllPrivacy {
    getAllPrivacy {
      count
      message
      statusCode
      data {
        _id
        createdAt
        updatedAt
        userId {
          _id
          firstName
          lastName
          email
        }
        permissions {
          type
          view
          update
          verify
          delete
          add
        }
      }
    }
  }
`;

export const GET_PRIVACY = gql`
  query GetPrivacy($_id: ID!) {
    getPrivacy(_id: $_id) {
      message
      statusCode
      data {
        _id
        createdAt
        updatedAt
        permissions {
          view
          update
          verify
          delete
          add
          type
        }
      }
    }
  }
`;

export const GET_ALL_CATEGORIES = gql`
  query GetAllCategories {
    getAllCategories {
      message
      statusCode
      data {
        _id
        categoryName
      }
    }
  }
`;

export const GET_HISTORY = gql`
  query GethistoryData {
    gethistoryData {
      message
      statusCode
      count
      data {
        _id
        description
        title
        metaTitle
        metaDescription
        metaKeywords
        focus
        yearsData {
          year
          yearDescription
          image
        }
      }
    }
  }
`;

export const GET_HISTORY_BY_ID = gql`
  query GetHistoryById($_id: ID!) {
    getHistoryById(_id: $_id) {
      data {
        _id
        description
        title
        images
        metaTitle
        metaDescription
        metaKeywords
        focus
        yearsData {
          year
          image
          yearDescription
        }
      }
    }
  }
`;

export const GET_ABOUT_SURAT = gql`
  query GetAboutSuratResponseData($page: Int, $limit: Int, $search: String) {
    getAboutSuratResponseData(page: $page, limit: $limit, search: $search) {
      message
      statusCode
      count
      data {
        _id
        additionalData
        description
        title
        metaTitle
        metaDescription
        metaKeywords
        focus
        images
        otherData
      }
    }
  }
`;

export const GET_ABOUT_SURAT_BY_ID = gql`
  query GetAboutSuratById($_id: ID!) {
    getAboutSuratById(_id: $_id) {
      message
      statusCode
      data {
        _id
        additionalData
        description
        title
        metaTitle
        metaDescription
        metaKeywords
        focus
        images
        otherData
      }
    }
  }
`;

export const GET_SUB_CATEGORY_RANK = gql`
  query GetSubCategories($page: Int, $limit: Int, $search: String) {
    getSubCategories(page: $page, limit: $limit, search: $search) {
      message
      statusCode
      count
      data {
        _id
        subCategoryName
        title
        viewRank
      }
    }
  }
`;

// events enum
export const GET_ALL_EVENTS_ENUM = gql`
  query GetAllEventsEnum {
    getAllEventsEnum {
      total
      message
      statusCode
      data {
        _id
        name
        description
      }
    }
  }
`;
export const GET_BUSINESS = gql`
  query GetBusinessProfilesAll(
    $page: Int
    $limit: Int
    $search: String
    $primaryCategory: [String]
    $pinCode: [String]
    $showOnWebFilter: String
    $verifiedByUsFilter: String
    $claimStatusFilter: String
    $category: String
  ) {
    getBusinessProfilesAll(
      page: $page
      limit: $limit
      search: $search
      primaryCategory: $primaryCategory
      pinCode: $pinCode
      showOnWebFilter: $showOnWebFilter
      verifiedByUsFilter: $verifiedByUsFilter
      claimStatusFilter: $claimStatusFilter
      category: $category
    ) {
      message
      statusCode
      count
      data {
        _id
        slug
        userId
        businessName
        pinCodeDetails
        reason
        tag
        ranking
        brandName
        brandLogo
        website
        businessEmail
        documents
        managedBy
        mobileNumber
        bgImage
        status
        isPromoted 
        verifiedBy
        verificationDate
        totalReviewsCount
        claimBusiness
        averageRating
        deleteFlag
        isstatusVerified
        classOfbusiness
        code
        googleBusinessStatus
        primarySubcategoryName
        additionalSubCategoriesName
        primarySubCategory {
          subCategoryName
        }
        address {
          # _id
          businessProfileId
          addressses {
            address
            buildingOrShopNumber
            landmark
            neighborhood
            googleMapLink
            pinCode {
              _id
              areaName
              pinCode
              rank
            }
          }
        }
      }
      subCategory {
        subCategoryName
        _id
      }
      pinCode {
        _id
        areaName
        pinCode
        metaTitle
        metaDescription
        metaKeywords
        imageUrl
        rank
        code
        slug
      }
    }
  }
`;
export const GET_ADSLINK = gql`
  query GetAllAdsLinks($page: Int, $limit: Int, $search: String,$type: [EntityTypeEnum]) {
    getAllAdsLinks(page: $page, limit: $limit, search: $search,type: $type) {
      message
      statusCode
      count
      data {
        _id
        imageUrl
        redirectLink
        title
        description
        subCategoryId
        createdAt
        updatedAt
        entityType
      }
    }
  }
`;

export const GET_ADSLINK_BY_ID = gql`
  query GetAdsLinkById($id: ID!) {
    getAdsLinkById(id: $id) {
      message
      statusCode
      data {
        _id
        imageUrl
        redirectLink
        title
        description
        subCategoryId
        createdAt
        updatedAt
        entityType
      }
    }
  }
`;
