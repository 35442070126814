import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Divider,
  Tooltip,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import WarningIcon from "@mui/icons-material/Warning";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import ArticleIcon from "@mui/icons-material/Article";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import { toast } from "react-toastify";

import CustomTable from "src/components/CustomTable";
import DeleteModel from "src/components/Common/DeleteModel";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";

import { GET_CLAIM_BUSINESSES } from "src/graphql/query";
import {
  DELETE_CLAIM_STATUS,
  UPDATE_CLAIM_STATUS,
} from "src/graphql/mutations";
import styles from "./ClaimBusiness.module.css";
import ApprovalIcon from "@mui/icons-material/Approval";
import VerifyEventsDialog from "../Events/VerifyEvents";
import { debounce } from "src/utils/debounce";
import { hasPermissionPage } from "src/components/Common/Utils";
function ClaimBusiness() {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 50 });
  const [deleteClaimBusinessId, setDeleteClaimBusinessId] = useState<
    string | null
  >(null);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);

  const [DeleteClaimBusiness] = useMutation(DELETE_CLAIM_STATUS);
  const [verifyingByUsBusiness, setVerifyingByUsBusiness] = useState<
    string | null
  >(null);
  const [UpdateClaimBusinessStatus] = useMutation(UPDATE_CLAIM_STATUS);
  const [reason, setReason] = useState<string>("");
  const [verifyBusinessByUsProfileValue, setVerifyBusinessByUsProfileValue] =
    useState<string>("");
  const COLUMNS = [
    {
      headerName: "Date",
      field: "createdAt",
      width: 120,
      renderCell: ({ value }: { value: string }) => {
        const formattedDate = new Date(value).toLocaleDateString("en-GB"); // Format: DD/MM/YYYY
        return <span>{formattedDate}</span>;
      },
    },
    {
      headerName: "Business Name",
      field: "businessName",
      width: 350,
      renderCell: ({ row }: any) => (
        <Box
          display="flex"
          justifyContent="start"
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            if (window) {
              const slug = row?.businessName
                ?.toLowerCase()
                ?.replace(/&/g, "")
                ?.replace(/[()]/g, "")
                ?.replace(/\s+/g, "-")
                ?.replace(/-+/g, "-")
                ?.replace(/[^a-z0-9-]/g, "");
              window.open(`/business/${slug}`, "_blank");
            }
          }}
        >
          {row.brandLogo ? (
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <Typography variant="body2" color="textSecondary">
                {row?.businessName}
              </Typography>
            </Box>
          ) : (
            <Typography variant="body2" color="textSecondary" mt={2}>
              {row?.businessName}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      headerName: "Name",
      field: "firstName",
      width: 200,
      renderCell: ({ row }: any) => (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="start"
          alignItems="start"
        >
          <Typography variant="body2" color="textPrimary">
            {row?.firstName || "---"} {row?.lastName || "---"}
          </Typography>

          <Typography variant="body2" color="textSecondary">
            {row?.designation || "---"}
          </Typography>
        </Box>
      ),
    },
    {
      headerName: "Contact Info",
      field: "mobileNumber",
      width: 200,
      renderCell: ({ row }: any) => (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="start"
          alignItems="start"
        >
          <Typography variant="body2" color="textPrimary">
            {row?.mobileNumber || "---"}
          </Typography>

          <Typography variant="body2" color="textSecondary">
            {row?.email || "---"}
          </Typography>
        </Box>
      ),
    },
    { field: "verifiedBy", headerName: "Verified By", width: 150 },
    { field: "gstinNumber", headerName: "GSTIN", width: 180 },
    {
      headerName: "View Document",
      field: "proofDocument",
      flex: 1,
      renderCell: ({ row }: { row: any }) => {
        if (!row?.proofDocument) {
          return null;
        }
        return (
          <Tooltip title="Download Document">
            <IconButton
              component="a"
              href={row.proofDocument}
              download
              aria-label="download document"
            >
              <ArticleIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      headerName: "Verification",
      field: "Verification",
      width: 150,
      renderCell: ({ row }: any) => (
        <Box>
          {hasPermissionPage("Blog", "verify") && (
            <>
              <Tooltip title={row?.claimStatus ?? "PENDING"}>
                <IconButton
                  aria-label="status"
                  style={{ color: getStatusColor(row?.claimStatus) }}
                >
                  {row?.claimStatus === "APPROVED" ? (
                    <TaskAltIcon />
                  ) : row?.claimStatus === "REJECTED" ? (
                    <CloseIcon />
                  ) : (
                    <WarningIcon />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title="Verify Claim Business">
                <IconButton
                  onClick={() => setVerifyingByUsBusiness(row._id)}
                  aria-label="status"
                  style={{ color: "#869986" }}
                >
                  <ApprovalIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      ),
    },
    {
      headerName: "Actions",
      width: 150,
      renderCell: ({ row }: { row: any }) => (
        <Box display={"flex"} gap={2}>
          {hasPermissionPage("ClaimBusiness", "delete") && (
            <Tooltip title="Delete">
              <IconButton
                onClick={() => {
                  setDeleteClaimBusinessId(row._id);
                  setOpenDeleteModel(true);
                }}
                sx={{ color: "#00C5B9" }}
                aria-label="delete"
                className={styles.button}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  const { data, loading, error, refetch } = useQuery(GET_CLAIM_BUSINESSES, {
    variables: { page: pagination.page, limit: pagination.pageSize },
  });
  useEffect(() => {
    if (data?.getClaimBusinesses?.data) {
      setList(data?.getClaimBusinesses?.data);
      setTotal(data?.getClaimBusinesses?.count || 0);
    }
  }, [data]);

  const handleClearSearch = () => {
    setSearchTerm("");
    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({ search: "", page: 1, limit: pagination.pageSize });
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage + 1 }));
    refetch({
      search: searchTerm,
      page: newPage + 1,
      limit: pagination.pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page,
      limit: newPageSize,
    });
  };

  const handleCloseDeleteModel = () => {
    setOpenDeleteModel(false);
    setDeleteClaimBusinessId(null);
  };

  const handleDelete = async () => {
    if (!deleteClaimBusinessId) return;
    try {
      const res = await DeleteClaimBusiness({
        variables: { _id: deleteClaimBusinessId },
      });
      if (res?.data?.deleteClaimBusiness?.statusCode === 200) {
        toast.success(res?.data?.deleteClaimBusiness?.message);
      } else {
        throw new Error(res?.data?.deleteClaimBusiness?.message);
      }
      refetch();
      setOpenDeleteModel(false);
    } catch (error: any) {
      toast.error(error?.message);
      console.error("Error deleting business:", error);
    }
  };

  const handleVerifyByUs = async (businessId: string) => {
    try {
      const res = await UpdateClaimBusinessStatus({
        variables: {
          _id: businessId,
          claimStatus: verifyBusinessByUsProfileValue,
          reason,
        },
      });

      if (res.errors) {
        throw new Error(
          `GraphQL error! message: ${res.errors
            .map((error) => error.message)
            .join(", ")}`
        );
      }

      refetch();
      setReason("");
      setVerifyBusinessByUsProfileValue("");
      toast.success(res?.data?.updateClaimBusinessStatus?.message);
      setVerifyingByUsBusiness(null);
    } catch (error) {
      console.error("Error verifying business:", error);
    }
  };

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term?.length > 0 ? term : "");
      refetch({
        search: term,
        page: pagination.page,
        limit: pagination.pageSize,
      });
    }, 1000),
    [refetch]
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    setPagination((prev) => ({ ...prev, page: 1 }));
    debouncedRefetch(value);
  };
  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        pr={4}
      >
        <Typography variant="h4" className={styles.primaryColor}>
          Claim Business
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <IconButton onClick={handleClearSearch}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Divider />
      {loading ? (
        <Loader />
      ) : error ? (
        <ErrorComponent />
      ) : (
        <CustomTable
          columns={COLUMNS}
          data={list}
          paginationModel={{
            page: pagination.page - 1,
            pageSize: pagination.pageSize,
          }}
          onPageSizeChange={handlePageSizeChange}
          onPageChange={handlePageChange}
          totalCount={total}
        />
      )}
      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={handleCloseDeleteModel}
          onConfirm={handleDelete}
          message="Are you sure you want to delete this claim business?"
        />
      )}

      <VerifyEventsDialog
        label="Business By us"
        handleVerify={handleVerifyByUs}
        setVerifyingData={setVerifyingByUsBusiness}
        verifyingData={verifyingByUsBusiness}
        verifyDataValue={verifyBusinessByUsProfileValue}
        reason={reason}
        setVerifyDataValue={setVerifyBusinessByUsProfileValue}
        setReason={setReason}
      />
    </Box>
  );
}

function getStatusColor(status: string) {
  switch (status) {
    case "APPROVED":
      return "#039487";
    case "REJECTED":
      return "#ff0000";
    default:
      return "#ff9966";
  }
}

export default ClaimBusiness;
