import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { GET_ABOUT_SURAT, GET_ABOUT_SURAT_BY_ID } from "src/graphql/query";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModel from "src/components/Common/DeleteModel";
import { toast } from "react-toastify";
import CustomButton from "src/components/CustomButton";
import { ClearIcon } from "@mui/x-date-pickers";
import { getRole, hasPermissionPage } from "src/components/Common/Utils";
import { useNavigate } from "react-router";
import { ROUTES } from "src/constant";
import EditIcon from "@mui/icons-material/Edit";
import { DELETE_ABOUT_SURAT } from "src/graphql/mutations";
import AboutSuratModal from "./AboutSuratModal";

interface AboutSurat {
  _id?: string;
  description?: string;
  title?: string;
  metaTitle?: string;
  metaDescription?: string;
}

const AboutDataList: React.FC = () => {
  const [list, setList] = useState<AboutSurat[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 0, pageSize: 50 });
  const [total, setTotal] = useState(0);
  const [deleteAboutSurat] = useMutation(DELETE_ABOUT_SURAT);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [deleteAboutSuratId, setDeleteAboutSuratId] = useState<string | null>(
    null
  );
  const [selectedPopularlocations, setSelectedPopularlocations] =
    useState<AboutSurat | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const isSuperAdmin = getRole() === "SuperAdmin";
  const navigate = useNavigate();
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});

  const toggleExpand = (id: string) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const COLUMNS = [
    ...(isSuperAdmin ? [] : []),
    { headerName: "Title", field: "title", flex: 1 },
    {
      headerName: "Description",
      field: "description",
      width: 700,
      renderCell: ({ value, row }: { value: string; row: any }) => {
        const expanded = expandedRows[row._id] || false;
        const maxLength = 90; // Adjust as needed

        return (
          <Box
            sx={{
              whiteSpace: expanded ? "pre-wrap" : "nowrap",
              overflow: "hidden",
              textOverflow: expanded ? "clip" : "ellipsis",
              width: "100%",
            }}
          >
            {expanded ? value : value.length > maxLength ? `${value.substring(0, maxLength)}...` : value}
            {value.length > maxLength && (
              <span
                onClick={(e) => {
                  e.stopPropagation(); // Prevents row selection
                  toggleExpand(row._id);
                }}
                style={{ color: "#00C5B9", cursor: "pointer", marginLeft: "10px" }}
              >
                {expanded ? "See Less" : "See More"}
              </span>
            )}
          </Box>
        );
      },
    },

    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: { _id: string } }) => (
        <Box display="flex" alignItems="center">
          {hasPermissionPage("AboutSurat", "update") && (
            // <IconButton
            //   onClick={() => navigate(`/aboutsurat/update/${row?._id}`)}
            //   aria-label="edit"
            //   style={{ marginRight: "8px", color: "#00C5B9" }}
            // >
            //   <EditIcon />
            // </IconButton>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => handleEdit(row)}
                aria-label="edit"
                style={{ marginRight: "8px", color: "#00C5B9" }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {hasPermissionPage("AboutSurat", "delete") && (
            <Tooltip title="Delete">
              <IconButton
                onClick={() => {
                  setDeleteAboutSuratId(row?._id);
                  setOpenDeleteModel(true);
                }}
                aria-label="delete"
                style={{ color: "#00C5B9" }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  const { loading, error, data, refetch } = useQuery(GET_ABOUT_SURAT, {});
  const [getAboutSurat] = useLazyQuery(GET_ABOUT_SURAT_BY_ID);

  const handleEdit = async (rowData: AboutSurat) => {
    try {
      const { data } = await getAboutSurat({
        variables: {
          _id: rowData?._id,
        },
        fetchPolicy: "no-cache",
      });
      setSelectedPopularlocations(data?.getAboutSuratById?.data);
      setOpenModal(true);
    } catch (error) {
      console.error("Error fetching about surat:", error);
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedPopularlocations(null);
  };
  useEffect(() => {
    if (data?.getAboutSuratResponseData) {
      setList(data?.getAboutSuratResponseData?.data);
      setTotal(data?.getAboutSuratResponseData?.count);
    }
  }, [data]);

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: 1,
        limit: pagination.pageSize,
      });
    }, 1000),
    [refetch, pagination.page, pagination.pageSize]
  );

  const handleRefetch = (search?: string) => {
    refetch({
      variables: {
        page: pagination.page,
        limit: pagination.pageSize,
        search: search ?? searchTerm,
      },
    });
  };

  const handleDelete = async () => {
    if (!deleteAboutSuratId) return;
    try {
      await deleteAboutSurat({ variables: { _id: deleteAboutSuratId } });
      setOpenDeleteModel(false);
      handleRefetch();
      toast.success("About Surat deleted successfully.");
    } catch (error) {
      toast.error("Error deleting about surat.");
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handlePageChange = (newPage: number) => {
    setPagination({ page: newPage, pageSize: pagination.pageSize });
    refetch({
      search: searchTerm,
      page: newPage + 1,
      limit: pagination.pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page + 1,
      limit: newPageSize,
    });
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({ search: "", page: 1, limit: pagination.pageSize });
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box p={2}>
      {!openModal && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            paddingRight={4}
          >
            <Typography variant="h4" color="#00C5B9">
              About Surat
            </Typography>

            <Box display="flex" alignItems="center" gap={2}>
              <TextField
                label="Search"
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={handleSearchChange}
                sx={{ width: 200 }}
                InputProps={{
                  endAdornment: searchTerm && (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClearSearch}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {hasPermissionPage("AboutSurat", "add") && (
                <Box>
                  <CustomButton
                    onClick={() => navigate(`${ROUTES.ABOUT_SURAT_ADD}`)}
                    variant="contained"
                    className="width: 200px"
                  >
                    Create
                  </CustomButton>
                </Box>
              )}
            </Box>
          </Box>

          <Divider />

          <Box mt={2}>
            <CustomTable
              columns={COLUMNS}
              data={list}
              paginationModel={pagination}
              totalCount={total}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              getRowHeight={({ id }) => (expandedRows[id] ? "auto" : null)}
            />
          </Box>
        </>
      )}
      {openModal && (
        <AboutSuratModal
          open={openModal}
          handleClose={handleCloseModal}
          aboutsurat={selectedPopularlocations}
          refetchAboutSurat={refetch}
        />
      )}
      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={() => setOpenDeleteModel(false)}
          onConfirm={handleDelete}
          message="Are you sure you want to delete this about surat?"
        />
      )}
    </Box>
  );
};

export default AboutDataList;
