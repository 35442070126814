import client from "src/apolloClient";
import { SIGN_IN_MUTATION, VERIFY_OTP } from "src/graphql/mutations";
import { SET_USER_ACTION } from "src/store/types/auth";

const {
  SET_USER_ACTION_REQUEST,
  SET_USER_ACTION_SUCCESS,
  SET_USER_ACTION_FAILURE,
} = SET_USER_ACTION;

// Action Creators
export const setEventRequest = (): any => ({
  type: SET_USER_ACTION_REQUEST,
});

export const setEventSuccess = (payload: string): any => ({
  type: SET_USER_ACTION_SUCCESS,
  payload,
});

export const setEventFailure = (error: string): any => ({
  type: SET_USER_ACTION_FAILURE,
  payload: error,
});

// export const SET_USERS = (email: string, password: string): any => {
//   return async (dispatch: any) => {
//     dispatch(setEventRequest());

//     try {
//       const response = await client.mutate({
//         mutation: SIGN_IN_MUTATION,
//         variables: { input: { email, password } },
//       });

//       if (response.data?.signInByAdmin?.statusCode === 200) {
//         dispatch(setEventSuccess(response.data));
//         return response.data;
//       } else {
//         dispatch(
//           setEventFailure(
//             response.data.signInByAdmin.message || "Failed to sign in"
//           )
//         );
//         throw new Error(
//           response.data.signInByAdmin.message || "Failed to sign in"
//         );
//       }
//     } catch (error: any) {
//       dispatch(setEventFailure(error.message || "An error occurred"));
//       throw error; // Rethrow error to be handled in the component
//     }
//   };
// };


export const SET_VERIFY_OTP = (email: string, otp: string): any => {
  return async (dispatch: any) => {
    dispatch(setEventRequest());

    try {
      const response = await client.mutate({
        mutation: VERIFY_OTP,
        variables: { email, otp },
      });

      if (response.data?.verifyOTP?.statusCode === 200) {
        dispatch(setEventSuccess(response.data));
        return response.data;
      } 
      else {
        dispatch(
          setEventFailure(
            response.data.verifyOTP.message || "Failed to verify OTP"
          )
        );
        throw new Error(
          response.data.verifyOTP.message || "Failed to verify OTP"
        );
      }
    } catch (error: any) {
      dispatch(setEventFailure(error.message || "An error occurred"));
      throw error;
    }
  };
};