import { useQuery } from "@apollo/client";
import React from "react";
import Select, { SingleValue } from "react-select";
import { GET_ALL_PINCODES, GET_PINCODES } from "src/graphql/query";
import { PincodeOption } from "./Utils";
import { FormControl, Typography } from "@mui/material";

interface PincodeSelectDropDownProps {
  selectedPincode: string | null;
  handlePincodeChange: (selectedOption: SingleValue<PincodeOption>) => void;
  error?: boolean;
  helperText?: string;
}

const PincodeSelectDropDown: React.FC<PincodeSelectDropDownProps> = ({
  selectedPincode,
  handlePincodeChange,
  error,
  helperText,
}) => {
  const {
    data: pincodeData,
    loading,
    error: queryError,
  } = useQuery(GET_ALL_PINCODES);

  if (loading) {
    return <p>Loading pincodes...</p>;
  }

  if (queryError) {
    return <p>Error loading pincodes</p>;
  }

  const pincodeOptions: PincodeOption[] =
    pincodeData?.getAllPinCodes?.data?.map((pincode: any) => ({
      value: pincode?._id,
      label: pincode?.areaName,
    })) || [];

  return (
    <FormControl fullWidth>
      <Select
        value={
          pincodeOptions.find((option) => option.value === selectedPincode) ||
          null
        }
        maxMenuHeight={200}
        placeholder="Select Pincode"
        options={pincodeOptions}
        onChange={handlePincodeChange}
        styles={{
          control: (base: any) => ({
            ...base,
            borderColor: error ? "red" : base?.borderColor,
            "&:hover": {
              borderColor: error
                ? "red"
                : base["&:hover"]?.borderColor || base?.borderColor,
            },
            boxShadow: error ? "0 0 0 1px red" : base?.boxShadow,
          }),
          dropdownIndicator: (base) => ({
            ...base,
            color: error ? "red" : base?.color,
          }),
          indicatorSeparator: (base) => ({
            ...base,
            backgroundColor: error ? "red" : base?.backgroundColor,
          }),
        }}
      />
      {error && helperText && (
        <Typography color="error" variant="body2">
          {helperText}
        </Typography>
      )}
    </FormControl>
  );
};

export default PincodeSelectDropDown;
