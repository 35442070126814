import React, { useState, useEffect, useCallback } from "react";
import * as Yup from "yup";
import {
  Box,
  TextField,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import styles from "./../Business/Business.module.css";
import {
  DELETE_BUSINESS,
  UPDATE_BUSINESS_RANK,
  VERIFY_BUSINESS,
  VERIFY_BUSINESS_BY_US,
} from "src/graphql/mutations";
import { GET_BUSINESS_PROFILES_BY_DATE } from "src/graphql/query";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "src/components/CustomButton";
import BusinessModal from "./../Business/BusinessModal";
import { toast } from "react-toastify";
import VerifyEventsDialog from "../Events/VerifyEvents";
import DeleteModel from "src/components/Common/DeleteModel";
import backButton from "../../Images/backButton.svg";
import ExcelUploadModal from "./../Business/ExcelUploadModal";
import { ClearIcon } from "@mui/x-date-pickers";
import WarningIcon from "@mui/icons-material/Warning";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ApprovalIcon from "@mui/icons-material/Approval";
import CloseIcon from "@mui/icons-material/Close";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { createInitialsImage } from "../Region/BusinessCard";
import { Formik, Field, Form } from "formik";
import {
  DELETE_ALL_BUSINESS,
  DELETE_MULTIPLE_BUSINESS,
} from "src/graphql/DeleteMutation";
import { getRole, hasPermissionPage } from "src/components/Common/Utils";
import ImageExcelUpload from "./../Business/ImageExcelUpload";

interface Business {
  _id: string;
  businessName: string;
  mobileNumber: string;
  status: string;
  verifiedBy: string;
}

const index: React.FC = () => {
  const [list, setList] = useState<Business[]>([]);
  const [selectedBusiness, setSelectedBusiness] = useState<Business | null>(
    null
  );
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedPincodes, setSelectedPincodes] = useState<string[]>([]);
  const [selectedShowWeb, setSelectedShowWeb] = useState<string>("");
  const [selectedVerifyByUser, setSelectedVerifyByUser] = useState<string>("");
  const [selectedClaim, setSelectedClaim] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 0, pageSize: 50 });
  const [total, setTotal] = useState(0);
  const [deleteBusiness] = useMutation(DELETE_BUSINESS);
  const [verifyBusinessProfile] = useMutation(VERIFY_BUSINESS);
  const [verifyBusinessProfileValue, setVerifyBusinessProfileValue] =
    useState<string>("");
  const [verifyingBusiness, setVerifyingBusiness] = useState<string | null>(
    null
  );
  const [verifyBusinessByUsProfileValue, setVerifyBusinessByUsProfileValue] =
    useState<string>("");
  const [className, setClassName] = useState<string>("");
  const [verifyingByUsBusiness, setVerifyingByUsBusiness] = useState<
    string | null
  >(null);
  const [isBusinessOpen, setIsBusinessPageOpen] = useState<boolean>(false);
  const [businessPageData, setBusinessPageData] = useState<any>();
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [openDeleteMultipleModel, setOpenDeleteMultipleModel] =
    useState<boolean>(false);
  const [openDeleteAllModel, setOpenDeleteAllModel] = useState<boolean>(false);
  const [deleteBusinessId, setDeleteBusinessId] = useState<string | null>(null);
  const [excelModel, setExcelModel] = useState<boolean>(false);
  const [imageModel, setImageModal] = useState<boolean>(false);
  const [VerifyBusinessProfileByUs] = useMutation(VERIFY_BUSINESS_BY_US);
  const [DeleteMultipleBusinessProfiles] = useMutation(
    DELETE_MULTIPLE_BUSINESS
  );
  const [DeleteAllBusinessProfiles] = useMutation(DELETE_ALL_BUSINESS);
  const [selectedRows, setSelectedRows] = useState([]);
  const [reason, setReason] = useState<string>("");
  const isSuperAdmin = getRole() === "SuperAdmin";

  const COLUMNS = [
    ...(isSuperAdmin ? [] : []),
    {
      headerName: "Logo",
      field: "businessName",
      width: 300,
      renderCell: ({ row }: any) => (
        <Box
          display="flex"
          justifyContent="start"
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            if (window) {
              const slug = row?.businessName
                ?.toLowerCase()
                ?.replace(/&/g, "")
                ?.replace(/[()]/g, "")
                ?.replace(/\s+/g, "-")
                ?.replace(/-+/g, "-")
                ?.replace(/[^a-z0-9-]/g, "");
              window.open(`/business/${slug}`, "_blank");
            }
          }}
        >
          {row.brandLogo ? (
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <img
                src={row?.bgImage ?? createInitialsImage(row?.businessName)}
                alt={row.bgImage}
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  margin: 4,
                }}
              />
              <Typography variant="body2" color="textSecondary">
                {row?.businessName}
              </Typography>
            </Box>
          ) : (
            <Typography variant="body2" color="textSecondary" mt={2}>
              {row?.businessName}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      headerName: "Category",
      field: "primarySubcategoryName",
      width: 200,
    },
    {
      headerName: "Contact Info",
      field: "mobileNumber",
      width: 200,
      renderCell: ({ row }: any) => (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="start"
          alignItems="start"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            if (window) {
              window.open(`/business/${row?._id}`, "_blank");
            }
          }}
        >
          {/* Mobile Number */}
          <Typography variant="body2" color="textPrimary">
            {row?.mobileNumber || "---"}
          </Typography>

          {/* Email */}
          <Typography variant="body2" color="textSecondary">
            {row?.businessEmail || "---"}
          </Typography>
        </Box>
      ),
    },
    { headerName: "Rating", field: "averageRating", width: 80 },

    ...(isSuperAdmin
      ? [{ headerName: "Verifier", field: "verifiedBy", width: 110 }]
      : []),
    {
      headerName: "Show Web",
      field: "status",
      width: 80,
      renderCell: ({ row }: any) => (
        <Tooltip title="Web live">
          {row?.status === "APPROVED" ? (
            <IconButton aria-label="status" style={{ color: "#039487" }}>
              <TaskAltIcon />
            </IconButton>
          ) : row?.status === "PENDING" ? (
            <IconButton aria-label="status" style={{ color: "#ff9966" }}>
              <WarningIcon />
            </IconButton>
          ) : (
            <IconButton aria-label="status" style={{ color: "#ff0000" }}>
              <CloseIcon />
            </IconButton>
          )}
        </Tooltip>
      ),
    },

    {
      headerName: "Verified",
      field: "isstatusVerified",
      width: 80,
      renderCell: ({ row }: any) => (
        <Tooltip title="Verified">
          {row?.isstatusVerified === "APPROVED" ? (
            <IconButton aria-label="status" style={{ color: "#039487" }}>
              <TaskAltIcon />
            </IconButton>
          ) : row?.isstatusVerified === "PENDING" ? (
            <IconButton aria-label="status" style={{ color: "#ff9966" }}>
              <WarningIcon />
            </IconButton>
          ) : (
            <IconButton aria-label="status" style={{ color: "#ff0000" }}>
              <CloseIcon />
            </IconButton>
          )}
        </Tooltip>
      ),
    },
    {
      headerName: "Claimed",
      field: "claimBusiness",
      width: 100,
      renderCell: ({ row }: any) => (
        <Tooltip title="claimBusiness">
          {row?.claimBusiness ? (
            <IconButton aria-label="status" style={{ color: "#039487" }}>
              <TaskAltIcon />
            </IconButton>
          ) : (
            <IconButton aria-label="status" style={{ color: "#ff9966" }}>
              <WarningIcon />
            </IconButton>
          )}
        </Tooltip>
      ),
    },
    {
      headerName: "Actions",
      width: 100,
      renderCell: ({ row }: { row: Business }) => (
        <Box display="flex" alignItems="center">
          {hasPermissionPage("business", "update") && (
            <Tooltip title="Edit">
              <IconButton
                onClick={() => handleEdit(row)}
                aria-label="edit"
                className={styles.button}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {hasPermissionPage("business", "delete") && (
            <Tooltip title="Delete">
              <IconButton
                onClick={() => {
                  setDeleteBusinessId(row._id);
                  setOpenDeleteModel(true);
                }}
                aria-label="delete"
                className={styles.button}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
    ...(hasPermissionPage("business", "verify")
      ? [
          {
            headerName: "Verification",
            field: "Verification",
            width: 100,
            renderCell: ({ row }: any) => (
              <>
                <Tooltip title="Web live">
                  {row?.status === "APPROVED" ? (
                    <IconButton
                      onClick={() => setVerifyingBusiness(row._id)}
                      aria-label="status"
                      style={{ color: "#039487" }}
                    >
                      <ThumbUpAltIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => setVerifyingBusiness(row._id)}
                      aria-label="status"
                      style={{ color: "#ff9966" }}
                    >
                      <WarningIcon />
                    </IconButton>
                  )}
                </Tooltip>

                <Tooltip title="Verify Business">
                  <IconButton
                    onClick={() => setVerifyingByUsBusiness(row._id)}
                    aria-label="status"
                    style={{ color: "#869986" }}
                  >
                    <ApprovalIcon />
                  </IconButton>
                </Tooltip>
              </>
            ),
          },
        ]
      : []),
    { headerName: "Class", field: "classOfbusiness", width: 80 },
    { headerName: "Google Status", field: "googleBusinessStatus", width: 150 },
    { headerName: "Reason", field: "reason", flex: 1 },
  ];

  const today = new Date();
  const fourteenDaysAgo = new Date();
  fourteenDaysAgo.setDate(today.getDate() - 14);

  const formatDate = (date: Date) => date.toISOString().split("T")[0];
  const [startDate, setStartDate] = useState(formatDate(fourteenDaysAgo));
  const [endDate, setEndDate] = useState(formatDate(today));
  const [previousEndDate, setPreviousEndDate] = useState(endDate);

  const { loading, error, data, refetch } = useQuery(
    GET_BUSINESS_PROFILES_BY_DATE,
    {
      variables: {
        startDate,
        endDate,
        page: pagination.page + 1,
        limit: pagination.pageSize,
        primaryCategory: selectedCategories,
        showOnWebFilter: selectedShowWeb,
        verifiedByUsFilter: selectedVerifyByUser,
        claimStatusFilter: selectedClaim,
      },
    }
  );

  const refetchData = (start: string, end: string) => {
    if (start && end) {
      refetch({ startDate: start, endDate: end });
    } else {
      alert("Please provide both start and end dates");
    }
  };

  const handleMultipleDelete = async () => {
    try {
      const res = await DeleteMultipleBusinessProfiles({
        variables: {
          _id: selectedRows,
        },
      });
      if (res?.data?.deleteMultipleBusinessProfiles?.statusCode === 200) {
        toast.success(res?.data?.deleteMultipleBusinessProfiles?.message);
        refetch();
      } else {
        throw new Error(res?.data?.deleteMultipleBusinessProfiles?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setOpenDeleteMultipleModel(false);
    }
  };
  const handleAllDelete = async () => {
    try {
      const res = await DeleteAllBusinessProfiles();
      if (res?.data?.deleteAllBusinessProfiles?.statusCode === 200) {
        toast.success(res?.data?.deleteAllBusinessProfiles?.message);
        refetch();
      } else {
        throw new Error(res?.data?.deleteAllBusinessProfiles?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setOpenDeleteAllModel(false);
    }
  };
  const handleRefetch = () => {
    refetch({
      variables: {
        page: pagination.page + 1,
        limit: pagination.pageSize,
      },
    });
  };

  useEffect(() => {
    if (data?.getBusinessProfilesAllByDate?.data) {
      setList(data?.getBusinessProfilesAllByDate?.data);
      setTotal(data?.getBusinessProfilesAllByDate?.count);
    }
  }, [data]);

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: pagination.page + 1,
        limit: pagination.pageSize,
        primaryCategory: selectedCategories,
      });
    }, 1000),
    [refetch, pagination.page, pagination.pageSize]
  );

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
    refetch({
      search: searchTerm,
      page: newPage + 1,
      limit: pagination.pageSize,
      startDate: startDate,
      endDate: endDate, // Keep endDate as it is
      primaryCategory: selectedCategories,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page + 1,
      limit: newPageSize,
      startDate: startDate,
      endDate: endDate, // Keep endDate as it is
      primaryCategory: selectedCategories,
    });
  };

  const handleEdit = async (rowData: any) => {
    if (rowData?._id) {
      window.open(`/business/edit/${rowData?._id}`, "_blank");
    } else {
      console.error("Invalid row data. Unable to open edit page.");
    }
  };

  const handleDelete = async () => {
    if (!deleteBusinessId) return;
    try {
      const res = await deleteBusiness({
        variables: { _id: deleteBusinessId },
      });
      if (res?.data?.deleteBusinessProfile?.statusCode === 200) {
        toast.success(res?.data?.deleteBusinessProfile?.message);
      } else {
        throw new Error(res?.data?.deleteBusinessProfile?.message);
      }
      handleRefetch();
      setOpenDeleteModel(false);
    } catch (error: any) {
      toast.error(error?.message);
      console.error("Error deleting business:", error);
    }
  };

  const handleVerify = async (businessId: string, status: string) => {
    try {
      const res = await verifyBusinessProfile({
        variables: {
          input: {
            _id: businessId,
            status,
            reason,
          },
        },
      });

      if (res.errors) {
        throw new Error(
          `GraphQL error! message: ${res.errors
            .map((error) => error.message)
            .join(", ")}`
        );
      }

      handleRefetch();
      toast.success(res?.data?.verifyBusinessProfile?.message);
      setVerifyingBusiness(null);
    } catch (error) {
      console.error("Error verifying business:", error);
    }
  };

  const handleVerifyByUs = async (businessId: string, status: string) => {
    try {
      const res = await VerifyBusinessProfileByUs({
        variables: {
          input: {
            _id: businessId,
            isstatusVerified: status,
            classOfbusiness: className,
            reason,
          },
        },
      });

      if (res.errors) {
        throw new Error(
          `GraphQL error! message: ${res.errors
            .map((error) => error.message)
            .join(", ")}`
        );
      }

      handleRefetch();
      toast.success(res?.data?.verifyBusinessProfileByUs?.message);
      setVerifyingByUsBusiness(null);
    } catch (error) {
      console.error("Error verifying business:", error);
    }
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handleCloseBusinessPage = () => {
    setIsBusinessPageOpen(false);
  };

  const handleCloseDeleteModel = () => {
    setOpenDeleteModel(false);
    setDeleteBusinessId(null);
  };

  const handleClearSearch = () => {
    setSearchTerm("");

    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({ search: "", page: 1, limit: pagination.pageSize });
  };

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <ErrorComponent />;
  }
  // validation for StartDate and EndDate
  const validationSchema = Yup.object().shape({
    startDate: Yup.string()
      .required("Start Date is required")
      .test("valid-date", "Invalid start date format", (value: any) => {
        return value && !isNaN(new Date(value).getTime());
      }),
    endDate: Yup.string()
      .required("End Date is required")
      .test("valid-date", "Invalid end date format", (value: any) => {
        return value && !isNaN(new Date(value).getTime());
      })
      .test(
        "is-after-or-equal-to-start",
        "End date cannot be before start date",
        function (value) {
          const { startDate } = this.parent;
          if (!value || !startDate) return true;
          return new Date(value) >= new Date(startDate); // Ensure endDate is not before startDate
        }
      ),
  });

  return (
    <>
      {openModal && (
        <BusinessModal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
            // setSelectedBusiness(null);
          }}
          business={selectedBusiness}
          // refetch={refetch}
        />
      )}

      {excelModel && (
        <ExcelUploadModal
          onCancel={() => setExcelModel(false)}
          refetch={refetch}
        />
      )}
      {imageModel && (
        <ImageExcelUpload
          onCancel={() => setImageModal(false)}
          refetch={refetch}
        />
      )}

      {!isBusinessOpen && !openModal && (
        <Formik
          initialValues={{
            startDate: formatDate(fourteenDaysAgo),
            endDate: formatDate(today),
          }}
          enableReinitialize
          onSubmit={(values) => {}}
          validationSchema={validationSchema} // Apply validation schema
        >
          {({
            setFieldValue,
            values,
            touched,
            errors,
            setFieldTouched,
            validateField,
          }) => {
            const [previousEndDate, setPreviousEndDate] = useState(
              values.endDate || ""
            ); // Track the previous endDate

            useEffect(() => {
              setFieldValue("startDate", startDate);
              setFieldValue("endDate", endDate);
            }, [startDate, endDate, setFieldValue]);

            const handleStartDateChange = (value: string) => {
              const selectedDate = new Date(value).toISOString().split("T")[0];
              setStartDate(selectedDate); // Update React state
              setFieldValue("startDate", selectedDate);
              setFieldTouched("startDate", true);
              validateField("startDate"); // Trigger validation on change
              validateField("endDate"); // Trigger validation for end date as well
              refetchData(selectedDate, endDate); // Use state endDate
            };

            const handleEndDateChange = (value: string) => {
              const selectedDate = new Date(value).toISOString().split("T")[0];

              // Compare with the start date and handle accordingly
              if (new Date(selectedDate) < new Date(startDate)) {
                setFieldValue("endDate", previousEndDate || ""); // Revert to previous value if invalid
              } else {
                setPreviousEndDate(selectedDate); // Update previous end date to the new valid one
                setEndDate(selectedDate); // Update endDate in React state
                setFieldValue("endDate", selectedDate); // Update Formik field value
              }

              refetchData(startDate, selectedDate); // Use state startDate
            };

            return (
              <Form>
                <Box p={2} overflow="hidden">
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                    gap={2}
                  >
                    {/* Title */}
                    <Typography variant="h4">Latest Business</Typography>

                    {/* Date Filters */}
                    <Box display="flex" gap={2}>
                      {/* StartDate */}
                      <TextField
                        label="Start Date"
                        variant="outlined"
                        size="small"
                        value={values.startDate || ""}
                        onChange={(e) => {
                          const selectedDate = new Date(e.target.value); // Get the selected start date
                          const today = new Date();

                          // Check if selected startDate is after endDate
                          if (selectedDate > new Date(values.endDate)) {
                            // If so, automatically set endDate to today's date
                            setFieldValue(
                              "endDate",
                              today.toISOString().split("T")[0]
                            );
                            setEndDate(today.toISOString().split("T")[0]); // Update endDate in React state
                          } else {
                            // If startDate is not greater than endDate, handle it as you already did
                            if (
                              selectedDate.getDate() !==
                                new Date(values.startDate).getDate() &&
                              selectedDate.getDate() !==
                                new Date(values.endDate).getDate()
                            ) {
                              handleStartDateChange(
                                selectedDate.toISOString().split("T")[0] // Call handleStartDateChange
                              );
                            }

                            setFieldValue("startDate", e.target.value); // Update Formik field value for startDate

                            // Check if today's date is selected and call handleStartDateChange
                            if (
                              selectedDate.getDate() === today.getDate() &&
                              selectedDate.getMonth() === today.getMonth() &&
                              selectedDate.getFullYear() === today.getFullYear()
                            ) {
                              handleStartDateChange(
                                selectedDate.toISOString().split("T")[0] // Call handleStartDateChange
                              );
                            }
                          }
                        }}
                        onBlur={() => {
                          if (values.startDate) {
                            setFieldTouched("startDate", true, true); // Trigger touched status for validation
                            validateField("startDate"); // Trigger validation onBlur
                          }
                        }}
                        error={touched.startDate && !!errors.startDate}
                        helperText={touched.startDate && errors.startDate}
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputProps: {
                            max: today.toISOString().split("T")[0], // Restrict to today or earlier
                          },
                        }}
                      />
                      {/* End Date */}

                      <TextField
                        label="End Date"
                        variant="outlined"
                        size="small"
                        value={values.endDate || ""}
                        onChange={(e) => {
                          const selectedDate = new Date(e.target.value);
                          const today = new Date();

                          // Check if selected endDate is before startDate or greater than today's date
                          if (selectedDate < new Date(values.startDate)) {
                            // If so, revert to the previous endDate
                            setFieldValue("endDate", endDate || ""); // Revert to previous value if startDate is after endDate
                          } else if (selectedDate > today) {
                            // If endDate is after today's date, set it to today's date
                            setFieldValue(
                              "endDate",
                              today.toISOString().split("T")[0]
                            );
                            setEndDate(today.toISOString().split("T")[0]); // Update state as well
                            setFieldTouched("endDate", true); // Mark the field as touched
                            validateField("endDate"); // Trigger validation
                          } else {
                            // Otherwise, set the new endDate
                            if (
                              selectedDate.getDate() !==
                              new Date(values.endDate).getDate()
                            ) {
                              handleEndDateChange(
                                selectedDate.toISOString().split("T")[0]
                              ); // Call handleEndDateChange
                            }

                            setFieldValue("endDate", e.target.value); // Update Formik field value for endDate
                            setFieldTouched("endDate", true); // Mark the field as touched
                            validateField("endDate"); // Trigger validation for endDate
                          }
                        }}
                        onFocus={() => {
                          // Automatically mark the field as touched and validate when the field is focused
                          setFieldTouched("endDate", true);
                          validateField("endDate");
                        }}
                        error={touched.endDate && !!errors.endDate}
                        helperText={touched.endDate && errors.endDate}
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputProps: {
                            min: values.startDate || "", // Ensure endDate is not before startDate
                            max: today.toISOString().split("T")[0], // Restrict to today or earlier
                          },
                        }}
                      />
                    </Box>

                    {/* Search Box */}
                    <Box width={200}>
                      <TextField
                        label="Search"
                        variant="outlined"
                        size="small"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        InputProps={{
                          endAdornment: searchTerm && (
                            <InputAdornment position="end">
                              <IconButton onClick={handleClearSearch}>
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>

                  <Divider sx={{ mb: 2 }} />

                  {/* Data Table */}
                  {/* CustomTable is assumed to be a component rendering the data */}
                  <CustomTable
                    columns={COLUMNS}
                    data={data?.getBusinessProfilesAllByDate?.data || []}
                    paginationModel={{
                      page: pagination.page,
                      pageSize: pagination.pageSize,
                    }}
                    onPageSizeChange={handlePageSizeChange}
                    onPageChange={handlePageChange}
                    totalCount={data?.getBusinessProfilesAllByDate?.count || 0}
                  />
                </Box>
              </Form>
            );
          }}
        </Formik>
      )}

      {isBusinessOpen && businessPageData && (
        <Box p={3} sx={{ maxWidth: 1200, mx: "auto", mt: "40px" }}>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <img
              src={backButton}
              alt="backButton"
              height={25}
              width={25}
              style={{ marginRight: "12px", cursor: "pointer" }}
              onClick={handleCloseBusinessPage}
            />
            <Typography
              variant="h4"
              gutterBottom
              style={{ color: "#00C5B9" }}
              marginTop={2}
            >
              Business Profile
            </Typography>
          </Box>

          {/* <BusinessProfiles businessProfile={businessPageData} /> */}
          {/* <Profile data={businessPageData} /> */}
          <Box mt={3}>
            <CustomButton
              onClick={handleCloseBusinessPage}
              color="primary"
              variant="outlined"
            >
              Close
            </CustomButton>
          </Box>
        </Box>
      )}

      <VerifyEventsDialog
        label="Business By us"
        className={className}
        setClassName={setClassName}
        handleVerify={handleVerifyByUs}
        setVerifyingData={setVerifyingByUsBusiness}
        verifyingData={verifyingByUsBusiness}
        verifyDataValue={verifyBusinessByUsProfileValue}
        reason={reason}
        setVerifyDataValue={setVerifyBusinessByUsProfileValue}
        setReason={setReason}
      />
      <VerifyEventsDialog
        label="Business Web"
        handleVerify={handleVerify}
        setVerifyingData={setVerifyingBusiness}
        verifyingData={verifyingBusiness}
        verifyDataValue={verifyBusinessProfileValue}
        reason={reason}
        setVerifyDataValue={setVerifyBusinessProfileValue}
        setReason={setReason}
      />

      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={handleCloseDeleteModel}
          onConfirm={handleDelete}
          message="Are you sure you want to delete this business?"
        />
      )}
      {openDeleteMultipleModel && (
        <DeleteModel
          open={openDeleteMultipleModel}
          onClose={() => {
            setOpenDeleteMultipleModel(false);
          }}
          onConfirm={handleMultipleDelete}
          message={`Are you sure you want to delete ${
            selectedRows?.length ?? 0
          } business?`}
        />
      )}
      {openDeleteAllModel && (
        <DeleteModel
          open={openDeleteAllModel}
          onClose={() => {
            setOpenDeleteAllModel(false);
          }}
          onConfirm={handleAllDelete}
          message="Are you sure you want to delete all business?"
        />
      )}
    </>
  );
};

export default index;
