import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { GET_SUB_CATEGORY_RANK } from "src/graphql/query";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import ToggleSwitch from "src/components/Common/ToggleSwitch";
import CustomButton from "src/components/CustomButton";
import SaveIcon from "@mui/icons-material/Save";
import { toast } from "react-toastify";
import { UPDATE_SUBCATEGORY_RANK } from "src/graphql/mutations";
import { ClearIcon } from "@mui/x-date-pickers";

interface ContactUs {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  subject: string;
  description: string;
  statusHistory: {
    status: string;
    adminComment: string;
    updatedAt: string;
  }[];
}

const SubcategoryRank: React.FC = () => {
  const [list, setList] = useState<ContactUs[]>([]);
  const [isRankEdit, setIsRankEdit] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 0, pageSize: 50 });
  const [total, setTotal] = useState(0);
  const [rankUpdates, setRankUpdates] = useState<{
    [key: string]: number | null;
  }>({});

  const COLUMNS = [
    { headerName: "Sub Category Name", field: "subCategoryName", flex: 1 },
    {
      headerName: "Rank",
      field: "viewRank",
      flex: 1,
      renderCell: ({ row }: { row: any }) => {
        const [rankValue, setRankValue] = useState(row?.viewRank || "");
        const handleRankChange = (value: string) => {
          setRankValue(value);
          setRankUpdates((prev) => ({
            ...prev,
            [row._id]: value === "" ? null : Number(value),
          }));
        };

        return isRankEdit ? (
          <TextField
            value={rankValue}
            onChange={(e) => handleRankChange(e.target.value)}
            placeholder="Enter Rank"
            variant="outlined"
            size="small"
            style={{ marginRight: "8px" }}
          />
        ) : (
          <Typography
            display="flex"
            alignItems="center"
            style={{ height: "100%" }}
          >
            {rankValue}
          </Typography>
        );
      },
    },
  ];

  const { loading, error, data, refetch } = useQuery(GET_SUB_CATEGORY_RANK, {
    variables: {
      page: pagination.page + 1,
      limit: pagination.pageSize,
    },
    notifyOnNetworkStatusChange: true,
  });

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: pagination.page + 1,
        limit: pagination.pageSize,
      });
    }, 1000),
    [refetch, pagination.page, pagination.pageSize]
  );

  useEffect(() => {
    if (data?.getSubCategories?.data) {
      setList(data.getSubCategories.data);
      setTotal(data.getSubCategories.count);
    }
  }, [data]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
    refetch({
      search: searchTerm,
      page: newPage + 1,
      limit: pagination.pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page + 1,
      limit: newPageSize,
    });
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({ search: "", page: 1, limit: pagination.pageSize });
  };

  const [updateSubCategoriesRank] = useMutation(UPDATE_SUBCATEGORY_RANK);

  const handleRefetch = (search?: string) => {
    refetch({
      variables: {
        page: pagination.page,
        limit: pagination.pageSize,
        search: search ?? searchTerm,
      },
    });
  };
  const handleRankUpdate = async () => {
    // Example `rankUpdates` structure: { "66d6fae0fbff32f2e73d4108": 10, "66d6fb35fbff32f2e73d410f": 11 }
    const input = Object.keys(rankUpdates).map((id) => ({
      subCategoryId: id,
      viewRrank: rankUpdates[id],
    }));

    try {
      const response = await updateSubCategoriesRank({
        variables: { input: { subcategories: input } },
      });

      if (
        response?.data?.updateSubCategoriesViewRanksBulk?.statusCode === 200
      ) {
        toast.success(response.data.updateSubCategoriesViewRanksBulk.message);
        setRankUpdates({}); // Clear local rank updates after successful mutation
      } else {
        throw new Error(
          response?.data?.updateSubCategoriesViewRanksBulk?.message ||
            "Unknown error"
        );
      }
    } catch (error: any) {
      toast.error(error.message || "Failed to update ranks");
    } finally {
      setIsRankEdit(false);
      handleRefetch();
    }
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;
  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        paddingRight={4}
      >
        <Typography variant="h4" color={"#00C5B9"}>
          Sub Category Rank
        </Typography>
        <ToggleSwitch mode={isRankEdit} setMode={setIsRankEdit} />
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ width: 200 }}
          InputProps={{
            endAdornment: searchTerm && (
              <InputAdornment position="end">
                <IconButton onClick={handleClearSearch}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {isRankEdit && (
          <Tooltip title="Save">
            <Box width={50}>
              <CustomButton
                onClick={handleRankUpdate}
                variant="contained"
                sx={{ width: 50 }}
              >
                <SaveIcon />
              </CustomButton>
            </Box>
          </Tooltip>
        )}
      </Box>
      <Divider />
      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={list}
          paginationModel={{
            page: pagination.page,
            pageSize: pagination.pageSize,
          }}
          totalCount={total}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>
    </Box>
  );
};

export default SubcategoryRank;
