import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Grid,
  Typography,
  IconButton,
  Checkbox,
  CircularProgress,
  Tooltip,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_POPULAR_LOCATIONS,
  UPDATE_POPULAR_LOCATIONS,
} from "src/graphql/mutations";
import PincodeSelectDropDown from "src/components/Common/PincodeSelectDropDown";
import TimingSelect from "src/components/Common/TimingSelect";
import { toast } from "react-toastify";
import Select from "react-select";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "src/components/CustomButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import RichTextEditor from "src/components/Common/TextEditor";
import { uploadImages } from "src/components/Common/Utils";
import backButton from "../../Images/backButton.svg";
import { GET_LOCATION_ENUM_ALL, GET_RANKS } from "src/graphql/query";

interface PopularLocationsModalProps {
  open: boolean;
  handleClose: () => void;
  popularlocations?: any;
  refetchPopularlocations: () => void;
}
interface Image {
  imageUrl: string;
  file?: File;
}
interface Timing {
  day: string;
  startTime: string;
  endTime: string;
  other: string;
}

const PopularLocationsModal: React.FC<PopularLocationsModalProps> = ({
  handleClose,
  popularlocations,
  refetchPopularlocations,
}) => {
  const [images, setImages] = useState<Image[]>([]);
  const [popularLocationData, setPopularLocationData] = useState<any>();
  const [timings, setTimings] = useState<Timing[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [applySameTime, setApplySameTime] = useState<boolean>(false);
  const [AddPopularLocation] = useMutation(CREATE_POPULAR_LOCATIONS);
  const [updatePopularlocations] = useMutation(UPDATE_POPULAR_LOCATIONS);
  const [draggingIndex, setDraggingIndex] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [locationCategories, setLocationCategories] = useState<any>([]);
  // Handle drag start
  const handleDragStart = (index: any) => {
    setDraggingIndex(index);
  };

  // Handle drag over
  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const handleDrop = (index: number) => {
    if (draggingIndex === null) return;

    const updatedImages = [...images];
    const [movedImage] = updatedImages.splice(draggingIndex, 1);
    updatedImages.splice(index, 0, movedImage);

    updatedImages.forEach((image) => {
      if (image.imageUrl) {
        URL.revokeObjectURL(image.imageUrl);
      }
    });
    setImages(updatedImages);

    setDraggingIndex(null);
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    about: Yup.string().required("Description is required"),
    address: Yup.string().required("Address is required"),
    pinCode: Yup.string().required("Pin Code is required"),
    google_location_link: Yup.string().required("Location is required"),
    // timings: Yup.object().required("Timings name required"),
  });

  const formik = useFormik({
    initialValues: {
      title: popularlocations?.title || "",
      address: popularlocations?.address || "",
      pinCode: popularlocations?.pincodeDetails?._id || "",
      google_location_link: popularlocations?.google_location_link || "",

      instagram: popularlocations?.instagram || "",
      youtube: popularlocations?.youtube || "",
      facebook: popularlocations?.facebook || "",
      twitter: popularlocations?.twitter || "",
      website: popularlocations?.website || "",
      phoneNumber: popularlocations?.phoneNumber || "",

      category: popularlocations?.category || "All",
      about: popularlocations?.about || "",
      metaTitle: popularlocations?.metaTitle || "",
      metaDescription: popularlocations?.metaDescription || "",
      metaKeywords: popularlocations?.metaKeywords || "",
      focus: popularlocations?.focus || "",
      rank: popularlocations?.rank || null,
      isPromoted: popularlocations?.isPromoted || false,
      ...(popularlocations?._id && { slug: popularlocations?.slug || "" }),
    },

    enableReinitialize: true,
    onSubmit: async () => handleSubmit,
  });

  const generateSlug = (value: string) => {
    return value
      .toLowerCase()
      .trim()
      .replace(/\s+/g, "-")
      .replace(/[^a-z0-9-]/g, "");
  };

  const { data, error } = useQuery(GET_LOCATION_ENUM_ALL, {
    onError: (err) => {
      toast.error("Facing issue in fetching categories");
      console.error("Query error:", err);
    },
    onCompleted: (data) => {
      const locationCategories = data?.getAllPopularLocationEnum?.data ?? [];
      const optionObj = locationCategories?.map((item: any) => ({
        value: item?.name,
        label: item?.name,
      }));
      setLocationCategories(optionObj);
    },
  });

  useEffect(() => {
    if (popularlocations) {
      setImages(
        popularlocations.images?.map((img: string) => ({ imageUrl: img })) || []
      );
      setTimings(
        popularlocations.timings?.map(
          ({ __typename, ...timing }: any) => timing
        ) || []
      );
    } else {
      formik.resetForm();
      setImages([{ imageUrl: "" }, { imageUrl: "" }, { imageUrl: "" }]);
      setTimings([{ day: "", startTime: "", endTime: "", other: "" }]);
    }
  }, [popularlocations]);

  const handleImageChange = (index: number, file: File | null) => {
    const updatedImages = [...images];
    if (file) {
      updatedImages[index] = { imageUrl: URL.createObjectURL(file), file };
    } else {
      updatedImages[index] = { imageUrl: "" };
    }
    setImages(updatedImages);
  };

  const handleRemoveImage = (index: number) => {
    const updatedImages = [...images];
    const removedImage = updatedImages[index];
    if (removedImage.imageUrl.startsWith("blob:")) {
      URL.revokeObjectURL(removedImage.imageUrl);
    }
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const addNewImageField = () => {
    setImages([...images, { imageUrl: "" }]);
  };

  const handleTimingChange = (
    index: number,
    field: keyof Timing,
    value: string
  ) => {
    const updatedTimings = [...timings];
    updatedTimings[index] = { ...updatedTimings[index], [field]: value };
    setTimings(updatedTimings);

    // Apply the same time to all days if the checkbox is checked
    if (
      applySameTime &&
      (field === "startTime" || field === "endTime" || field === "other")
    ) {
      const firstTiming = updatedTimings[0];
      const newTimings = updatedTimings?.map((timing, idx) => ({
        ...timing,
        startTime: firstTiming.startTime,
        endTime: firstTiming.endTime,
        other: firstTiming.other,
      }));
      setTimings(newTimings);
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setApplySameTime(e.target.checked);

    if (e.target.checked) {
      // Apply the first timing to all days
      const firstTiming = timings[0];
      const newTimings = Array.from({ length: 7 }, (_, idx) => ({
        day: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ][idx],
        startTime: firstTiming.startTime,
        endTime: firstTiming.endTime,
        other: firstTiming.other,
      }));
      setTimings(newTimings);
    }
  };
  const [amenities, setAmenities] = useState<string[]>(
    popularlocations?.amenities || []
  );
  const addNewTimingField = () => {
    if (timings.length < 7) {
      setTimings([
        ...timings,
        { day: "", startTime: "", endTime: "", other: "" },
      ]);
    }
  };

  const removeTimingField = (index: number) => {
    const updatedTimings = timings.filter((_, i) => i !== index);
    setTimings(updatedTimings);
  };
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault(); // Prevent page refresh
    setIsLoading(true);

    try {
      // Validate the form values
      await validationSchema.validate(formik.values, { abortEarly: false });

      // Manually validate URLs
      const validationErrors: Record<string, string> = {};
      Object.keys(formik?.values).forEach((key) => {
        const value = formik.values[key];
        if (
          value &&
          platformRegexes[key] &&
          !platformRegexes[key].test(value)
        ) {
          validationErrors[key] = `Enter a valid ${key} URL (Example: ${
            urlExamples[key] || "https://example.com"
          })`;
        }
      });

      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        setIsLoading(false);
        return;
      }

      // Prepare images for upload
      const filesToUpload = images
        .filter((image) => image.file)
        .map((image) => image.file);

      // Upload images if any exist
      const uploadedImages = filesToUpload?.length
        ? await uploadImages(
            filesToUpload as any,
            "exploreplaces",
            formik.values?.title
          )
        : [];
      // Extract URLs of uploaded images
      const uploadedImageUrls =
        uploadedImages?.uploadMultipleImages?.map((img: any) => img.url) ||
        uploadedImages?.map((img: any) => img.url);

      // Get existing image URLs
      const existingImageUrls = images
        .filter((image) => !image.file)
        .map((image) => image.imageUrl);

      // Combine existing and uploaded images
      const updatedImages = [...existingImageUrls, ...uploadedImageUrls];

      // Prepare variables for mutation
      const variables = {
        input: {
          ...formik.values,
          phoneNumber: formik?.values?.phoneNumber
            ? formik?.values?.phoneNumber
            : undefined, // If phoneNumber is empty/null, send undefined
          amenities,
          images: updatedImages,
          timings: timings,
        },
      };

      // Perform mutation (create or update)
      const res = popularlocations
        ? await updatePopularlocations({
            variables: { _id: popularlocations._id, input: variables.input },
          })
        : await AddPopularLocation({ variables });

      // Handle response
      if (res?.data) {
        toast.success(
          res.data.createPopularLocations?.message ||
            res.data.updatePopularLocations?.message ||
            "Location updated successfully!"
        );
        refetchPopularlocations();
        handleClose();
      }
    } catch (error: any) {
      // Handle validation and other errors
      if (error.inner) {
        const validationErrors: Record<string, string> = {};
        error.inner.forEach((err: any) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors); // Set errors state
      } else {
        toast.error(error.message || "Error submitting data.");
      }
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  const customHandleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    let transformedValue = value;

    if (name === "UserName") {
      transformedValue = value?.toLowerCase()?.replace(/\s+/g, "_");
    }

    if (name === "title") {
      formik.setFieldValue("UserName", transformedValue);

      if (popularlocations?._id) {
        formik.setFieldValue("slug", generateSlug(value));
      }
    }

    if (name === "slug") {
      transformedValue = value?.toLowerCase()?.replace(/\s+/g, "-");
    }

    formik.setFieldValue(name, transformedValue);
    setPopularLocationData((prev: any) => ({
      ...prev,
      [name]: transformedValue,
    }));
  };

  const handleSelectForAll = (index: number) => {
    const selectedTiming = timings[index];

    setTimings(
      timings.map((timing) => ({
        ...timing,
        startTime: selectedTiming.startTime,
        endTime: selectedTiming.endTime,
        other: selectedTiming.other,
      }))
    );
  };

  const handleAddAmenity = () => {
    setAmenities((prev) => [...prev, ""]);
  };

  const handleRemoveAmenity = (index: number) => {
    const updatedAmenities = amenities?.filter((_, i) => i !== index);
    setAmenities(updatedAmenities);
  };

  const handleAmenityChange = (index: number, value: string) => {
    const updatedAmenities = [...amenities];
    updatedAmenities[index] = value;
    setAmenities(updatedAmenities);
  };

  const usedDays = timings.map((timing) => timing.day);
  const RankOptions = useQuery(GET_RANKS);
  const totalRanks = RankOptions?.data?.getPopularLocations?.count ?? 0;
  const assignedRanks = RankOptions?.data?.getPopularLocations?.data
    .map((location: any) => location.rank)
    .filter((rank: any) => rank !== null);

  const rankOptions = Array.from({ length: totalRanks }, (_, i) => {
    const rank = i + 1;
    return {
      value: rank,
      label: `${rank}`,
      isDisabled: assignedRanks.includes(rank),
    };
  });

  const urlExamples: Record<string, string> = {
    instagram: "https://www.instagram.com/testprofile",
    facebook: "https://www.facebook.com/testprofile",
    youtube: "https://www.youtube.com/testchannel",
  };

  const platformRegexes: Record<string, RegExp> = {
    instagram: /^(https?:\/\/(www\.)?instagram\.com\/[a-zA-Z0-9._-]+\/?)$/,
    facebook: /^(https?:\/\/(www\.)?facebook\.com\/[a-zA-Z0-9.]+\/?)$/,
    youtube: /^(https?:\/\/(www\.)?youtube\.com\/.*)$/,
    website: /^(https?:\/\/[a-zA-Z0-9.-]+(?:\.[a-zA-Z]{2,})+.*)$/,
  };

  const handleSocialMediaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);

    if (!value) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: "",
      }));
      return;
    }

    const urlRegex = /^(https?:\/\/)/;
    if (!urlRegex.test(value)) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: `Enter a valid URL (must start with http or https, Example: ${
          urlExamples[name] || "https://example.com"
        })`,
      }));
      return;
    }

    if (platformRegexes[name] && !platformRegexes[name].test(value)) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: `Enter a valid ${name} URL (Example: ${
          urlExamples[name] || "https://example.com"
        })`,
      }));
      return;
    }

    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let numericValue = value?.replace(/\D/g, "");
    if (numericValue?.length > 10) {
      numericValue = numericValue.slice(0, 10);
    }
    formik.setFieldValue(name, numericValue);
  };

  return (
    <Box sx={{ padding: "20px", width: "100%" }}>
      <Box
        display={"flex"}
        justifyContent={"start"}
        mb={2}
        alignItems={"center"}
      >
        <Tooltip title="Back">
          <img
            src={backButton}
            alt="backButton"
            height={25}
            width={25}
            style={{ marginRight: "12px", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Tooltip>
        <Typography variant="h4" style={{ color: "#00C5B9" }}>
          {popularlocations
            ? "Update Popular Location"
            : "Create Popular Location"}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box mb={3}>
          <TextField
            label="Title"
            name="title"
            value={formik.values.title}
            onChange={customHandleChange}
            fullWidth
            variant="outlined"
            error={!!errors.title}
            helperText={errors.title}
          />
        </Box>
        {popularlocations && (
          <Box mb={3}>
            <TextField
              label="Slug"
              name="slug"
              value={formik?.values?.slug}
              onChange={customHandleChange}
              fullWidth
              variant="outlined"
              inputProps={{ style: { textTransform: "lowercase" } }}
              error={!!errors?.slug}
              helperText={errors?.slug}
            />
          </Box>
        )}

        <FormControl component="fieldset" fullWidth margin="normal">
          <FormControlLabel
            control={
              <Checkbox
                name="isPromoted"
                checked={formik?.values?.isPromoted}
                onChange={formik?.handleChange}
              />
            }
            label="Is Promoted"
          />
        </FormControl>

        <Box mb={3}>
          <Typography variant="h6" mx={1}>
            About
          </Typography>
          <RichTextEditor
            value={popularLocationData?.about || formik?.values?.about}
            onChange={(value) => {
              formik.setValues((prev: any) => ({ ...prev, ["about"]: value }));
              setPopularLocationData((prevBlog: any) => ({
                ...prevBlog,
                ["about"]: value,
              }));
            }}
          />
          {errors.about && (
            <Typography variant="caption" color="error" mx={1.5}>
              {errors.about}
            </Typography>
          )}
        </Box>
        <Box mb={3}>
          <TextField
            label="Address"
            name="address"
            value={formik.values.address}
            onChange={customHandleChange}
            fullWidth
            variant="outlined"
            error={!!errors.address}
            helperText={errors.address}
          />
        </Box>
        <Box mb={3}>
          <PincodeSelectDropDown
            selectedPincode={formik.values.pinCode}
            handlePincodeChange={(data: any) => {
              formik.setValues((prev: any) => ({
                ...prev,
                ["pinCode"]: data?.value,
              }));
              setPopularLocationData((prev: any) => ({
                ...prev,
                ["pinCode"]: data?.value,
              }));
            }}
          />
          {errors.pinCode && (
            <Typography variant="caption" color="error" mx={1.5}>
              {errors.pinCode}
            </Typography>
          )}
        </Box>

        <Box mb={3}>
          <Typography variant="h6">Social Media Links</Typography>
          <Box mb={3}>
            <TextField
              label="Instagram"
              name="instagram"
              value={formik?.values?.instagram}
              onChange={handleSocialMediaChange}
              fullWidth
              variant="outlined"
              error={!!errors?.instagram}
              helperText={errors?.instagram}
            />
          </Box>
          <Box mb={3}>
            <TextField
              label="YouTube"
              name="youtube"
              value={formik?.values?.youtube}
              onChange={handleSocialMediaChange}
              fullWidth
              variant="outlined"
              error={!!errors?.youtube}
              helperText={errors?.youtube}
            />
          </Box>
          <Box mb={3}>
            <TextField
              label="Facebook Link"
              name="facebook"
              value={formik?.values?.facebook}
              onChange={handleSocialMediaChange}
              fullWidth
              variant="outlined"
              error={!!errors?.facebook}
              helperText={errors?.facebook}
            />
          </Box>
          <Box mb={3}>
            <TextField
              label="Twitter Link"
              name="twitter"
              value={formik?.values?.twitter}
              onChange={handleSocialMediaChange}
              fullWidth
              variant="outlined"
              error={!!errors?.twitter}
              helperText={errors?.twitter}
            />
          </Box>
          <Box mb={3}>
            <TextField
              label="Website Link"
              name="website"
              value={formik?.values?.website}
              onChange={handleSocialMediaChange}
              fullWidth
              variant="outlined"
              error={!!errors?.website}
              helperText={errors?.website}
            />
          </Box>
          <TextField
            label="Phone Number"
            name="phoneNumber"
            value={formik?.values?.phoneNumber}
            onChange={handlePhoneNumberChange}
            fullWidth
            variant="outlined"
          />
        </Box>

        <Box mb={3}>
          <Typography variant="h6">Amenities</Typography>
          {amenities?.map((amenity, index) => (
            <Box key={index} display="flex" alignItems="center" mb={2}>
              <TextField
                value={amenity}
                onChange={(e) => handleAmenityChange(index, e.target.value)}
                fullWidth
                variant="outlined"
                label="Amenity"
              />
              <IconButton
                color="error"
                onClick={() => handleRemoveAmenity(index)}
                style={{ marginLeft: "10px" }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          ))}
          <CustomButton variant="outlined" onClick={handleAddAmenity}>
            Add Amenity
          </CustomButton>
        </Box>
        <Box mb={3}>
          <TextField
            label="Google Location Link"
            name="google_location_link"
            value={formik.values.google_location_link}
            onChange={customHandleChange}
            fullWidth
            variant="outlined"
            error={!!errors.google_location_link}
            helperText={errors.google_location_link}
          />
        </Box>
        <Box
          mb={3}
          display={"grid"}
          gap={2}
          gridTemplateColumns={"repeat(2, 1fr)"}
          width={"100%"}
        >
          <Box>
            <Typography variant="h6" mx={1}>
              Select Category
            </Typography>
            <Select
              options={locationCategories}
              placeholder="Select Category"
              value={{
                value: popularLocationData?.category || formik.values.category,
                label: popularLocationData?.category || formik.values.category,
              }}
              onChange={(selected) => {
                formik.setValues((prev: any) => ({
                  ...prev,
                  ["category"]: selected?.value,
                }));
                setPopularLocationData((prev: any) => ({
                  ...prev,
                  ["category"]: selected?.value,
                }));
              }}
              maxMenuHeight={300}
              isClearable
            />
          </Box>
          <Box>
            <Typography variant="h6" mx={1}>
              Select Rank
            </Typography>

            <Select
              options={rankOptions}
              placeholder="Select Rank"
              value={{
                value: popularLocationData?.rank || formik.values.rank,
                label: `${popularLocationData?.rank || formik.values.rank}`,
              }}
              onChange={(selected) => {
                formik.setValues((prev: any) => ({
                  ...prev,
                  ["rank"]: selected?.value,
                }));
                setPopularLocationData((prev: any) => ({
                  ...prev,
                  ["rank"]: selected?.value,
                }));
              }}
              maxMenuHeight={300}
              isClearable
            />
          </Box>
        </Box>

        <Box mb={3}>
          <Grid container spacing={2}>
            {images.map((image, index) => (
              <Grid
                item
                xs={4}
                key={index}
                draggable
                onDragStart={() => handleDragStart(index)}
                onDragOver={handleDragOver}
                onDrop={() => handleDrop(index)}
              >
                <Box position="relative">
                  <img
                    src={
                      image.imageUrl ||
                      "https://via.placeholder.com/150x150?text=Add+Image"
                    }
                    alt={`Image ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "150px",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                  <Tooltip title="Remove">
                    <IconButton
                      size="small"
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                      }}
                      onClick={() => handleRemoveImage(index)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                  <CustomButton
                    variant="contained"
                    component="label"
                    style={{ marginTop: "10px", width: "100%" }}
                  >
                    {image.imageUrl ? "Change Image" : "Add Image"}
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      hidden
                      onChange={(e) =>
                        handleImageChange(
                          index,
                          e.target.files ? e.target.files[0] : null
                        )
                      }
                    />
                  </CustomButton>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box mt={2}>
            <CustomButton onClick={addNewImageField} variant="outlined">
              Add New Image
            </CustomButton>
          </Box>
        </Box>
        {/* Timings Management */}
        <Box sx={{ marginTop: "20px" }}>
          <Typography variant="h6">Timings:</Typography>
          <Box display="flex" alignItems="center" mb={2}>
            <Checkbox
              checked={applySameTime}
              onChange={handleCheckboxChange}
              inputProps={{ "aria-label": "Apply same time to all days" }}
            />
            <Typography variant="body2" ml={1}>
              Apply same timing for all days
            </Typography>
          </Box>

          {timings.map((timing, index) => (
            <Box key={index} mt={2}>
              <TimingSelect
                key={index}
                timings={timings}
                onTimingChange={handleTimingChange}
                onRemove={removeTimingField}
                availableDays={usedDays}
                index={index}
              />
              <Box sx={{ width: "300px" }} my={1}>
                <CustomButton
                  variant="outlined"
                  onClick={() => handleSelectForAll(index)}
                >
                  Apply Timing to All Days
                </CustomButton>
              </Box>
              {errors.timings && (
                <Typography variant="caption" color="error" mx={1.5}>
                  {errors.timings}
                </Typography>
              )}
            </Box>
          ))}
          <Box my={2}>
            <CustomButton onClick={addNewTimingField}>Add Timing</CustomButton>
          </Box>
        </Box>
        <Box mb={3}>
          <TextField
            label="Meta Title"
            name="metaTitle"
            value={formik?.values?.metaTitle}
            onChange={customHandleChange}
            fullWidth
            variant="outlined"
          />
        </Box>
        <Box mb={3}>
          <TextField
            type="text-area"
            label="Meta Description"
            name="metaDescription"
            value={formik?.values?.metaDescription}
            onChange={customHandleChange}
            fullWidth
            variant="outlined"
            multiline
            rows={4}
          />
        </Box>
        <Box mb={3}>
          <TextField
            type="text-area"
            label="Meta Keywords"
            name="metaKeywords"
            value={formik?.values?.metaKeywords}
            onChange={customHandleChange}
            fullWidth
            variant="outlined"
            multiline
            rows={4}
          />
        </Box>
        <Box mb={3}>
          <TextField
            type="text-area"
            label="Focus Keywords"
            name="focus"
            value={formik?.values?.focus}
            onChange={customHandleChange}
            fullWidth
            variant="outlined"
            multiline
            rows={4}
          />
        </Box>
        <Box mt={4} display="flex" justifyContent="flex-end">
          <CustomButton variant="outlined" onClick={handleClose}>
            Cancel
          </CustomButton>
          <CustomButton
            // onClick={handleSubmit}
            color="primary"
            variant="contained"
            type="submit"
            style={{ marginLeft: "10px" }}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : popularlocations ? (
              "Update"
            ) : (
              "Create"
            )}
          </CustomButton>
        </Box>
      </form>
    </Box>
  );
};

export default PopularLocationsModal;
