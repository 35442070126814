import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
} from "@mui/material";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CREATE_BUSINESS_PROFILE,
  UPDATE_BUSINESS_PROFILE,
} from "src/graphql/mutations";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import { uploadImage, uploadImages } from "src/components/Common/Utils";
import { GET_ALL_SUBCATEGORIES, GET_BUSINESS_PROFILE } from "src/graphql/query";
import {
  addressInfoValidationSchema,
  BasicInfoValidationSchema,
  businessTimingValidationSchema,
  FormValues,
} from "./Business";
import backButton from "../../Images/backButton.svg";
import BasicInfo from "./BasicInfo";
import AddressInfo from "./AddressInfo";
import BusinessTIming from "./BusinessTIming";
import BusinessImages from "./BusinessImages";
import Loader from "src/components/Loader";
import { images } from "mammoth";
interface Timing {
  day: string;
  startTime: string;
  endTime: string;
  __typename?: string;
}

const BusinessModal = ({ onClose }: any) => {
  const [loading, setLoading] = useState(true);
  const [business, setBusiness] = useState<any>(null);
  const [overviewJson, setOverviewJson] = useState<boolean>(false);
  const [categoryId, setCategoryId] = useState<string>("");
  const [timings, setTimings] = useState<Timing[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [createBusinessProfile] = useMutation(CREATE_BUSINESS_PROFILE);
  const [updateBusinessProfile] = useMutation(UPDATE_BUSINESS_PROFILE);
  const [gallaryImages, setGallaryImages] = useState<any[]>([]);
  const [draggingIndex, setDraggingIndex] = useState<any>(null);
  const [showOwnerDetails, setShowOwnerDetails] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [businessImages, setbusinessImages] = useState<any[]>([]);
  const [mainImageFile, setMainImageFile] = useState<File | null>(null);
  const [mainImageUrl, setMainImageUrl] = useState<string | null>(null);
  const [bgImageFile, setBgImageFile] = useState<File | null>(null);
  const [bgImageUrl, setBgImageUrl] = useState<string | null>(null);
  const [
    fetchBusinessProfile,
    { data: businessData, loading: businessLoading },
  ] = useLazyQuery(GET_BUSINESS_PROFILE);
  const { data } = useQuery(GET_ALL_SUBCATEGORIES, {
    notifyOnNetworkStatusChange: true,
  });
  const subCategoryData = data?.getAllSubCategories?.data;
  useEffect(() => {
    const fetchData = async () => {
      if (typeof window !== "undefined" && window.location.pathname) {
        const pathname = window.location.pathname;
        const id = pathname.includes("/create")
          ? null
          : pathname.split("/").slice(3, 4)[0];

        if (id) {
          try {
            const { data: fetchedBusinessData } = await fetchBusinessProfile({
              variables: { slug: id },
            });
            setMainImageUrl(
              fetchedBusinessData?.getBusinessProfilebyslug?.data?.brandLogo
            );
            setBusiness(fetchedBusinessData?.getBusinessProfilebyslug?.data);
            const galleries =
              fetchedBusinessData?.getBusinessProfilebyslug?.data?.image || [];
            setGallaryImages(galleries || []);
            setTimings(
              fetchedBusinessData?.getBusinessProfilebyslug?.data?.timings?.[0]
                ?.timings || []
            );
          } catch (error) {
            console.error("Error fetching business profile:", error);
          }
        } else {
          setBusiness(null);
        }
      }
      setLoading(false);
    };
    fetchData();
  }, [fetchBusinessProfile]);
  const [initialValues, setInitialValues] = useState({
    businessName: "",
    primarySubCategory: "",
    additionalSubCategories: [],
    brandName: "",
    brandLogo: "",
    website: "",
    businessEmail: "",
    documents: [],
    managedBy: "",
    mobileNumber: "",
    metaTitle: "",
    metaDescription: "",
    focus: "",
    socialMedia: [],
    owners: {
      firstName: "",
      lastName: "",
      mobileNumber: "",
      email: "",
      designation: "",
    },
    addresses: [
      {
        address: "",
        buildingOrShopNumber: "",
        landmark: "",
        neighborhood: "",
        googleMapLink: "",
        pinCode: "",
      },
    ],
    gallery: [],
    timing: [
      {
        day: "",
        startTime: "",
        endTime: "",
        others: "",
      },
    ],
    overview: null,
    isPromoted: false,
  });
  useEffect(() => {
    if (business?.images) {
      setbusinessImages(business?.images);
    }
  }, [business]);
  useEffect(() => {
    if (business) {
      setInitialValues({
        businessName: business?.businessName || "",
        primarySubCategory: business?.primarySubCategory?._id || "",
        additionalSubCategories: business
          ? business?.additionalSubCategories?.map(
              (subCategory: any) => subCategory._id
            ) || []
          : [],
        brandName: business?.brandName || "",
        brandLogo: business?.brandLogo || "",
        website: business?.website || "",
        businessEmail: business?.businessEmail || "",
        documents: [],
        managedBy: business?.managedBy || "",
        mobileNumber: business?.mobileNumber || "",
        metaTitle: business?.metaTitle || "",
        metaDescription: business?.metaDescription || "",
        focus: business?.focus || "",
        socialMedia: business?.socialMedia || [],
        owners: business?.owners
          ? {
              firstName: business?.owners[0]?.firstName,
              lastName: business?.owners[0]?.lastName,
              mobileNumber: business?.owners[0]?.mobileNumber,
              email: business?.owners[0]?.email,
              designation: business?.owners[0]?.designation,
            }
          : {
              firstName: "",
              lastName: "",
              mobileNumber: "",
              email: "",
              designation: "",
            },
        addresses: business?.address?.addressses ?? [
          {
            address: "",
            buildingOrShopNumber: "",
            landmark: "",
            neighborhood: "",
            googleMapLink: "",
            pinCode: "",
          },
        ],
        gallery: business?.galleries?.[0]?.image ?? [],
        timing: business?.timings?.[0]?.timings || [
          {
            day: "",
            startTime: "",
            endTime: "",
            others: "",
          },
        ],
        overview: null,
        isPromoted: business?.isPromoted || false,
      });
    }
  }, [business]);
  // Handle drag start
  const handleDragStart = (index: any) => {
    setDraggingIndex(index);
  };

  // Handle drag over
  const handleDragOver = (event: any) => {
    event.preventDefault();
  };
  const handleDrop = (index: number) => {
    if (draggingIndex === null) return;

    const updatedImages = [...gallaryImages];
    const [movedImage] = updatedImages.splice(draggingIndex, 1);
    updatedImages.splice(index, 0, movedImage);

    updatedImages.forEach((image) => {
      if (image.imageUrl) {
        URL.revokeObjectURL(image.imageUrl);
      }
    });
    setGallaryImages(updatedImages);
    setDraggingIndex(null);
  };

  const handleNext = async (values: any) => {
    let validationSchema;

    switch (activeStep) {
      case 0:
        validationSchema = BasicInfoValidationSchema;
        break;
      case 1:
        validationSchema = addressInfoValidationSchema;
        break;
      case 2:
        validationSchema = businessTimingValidationSchema;
        break;
      default:
        break;
    }

    try {
      validationSchema &&
        (await validationSchema.validate(values, { abortEarly: false }));
      setActiveStep((prevStep) => prevStep + 1);
    } catch (error: any) {
      console.error(error);
      error?.inner?.forEach((err: any) => {
        toast.error(err.message);
      });
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleImageChange = async (
    field: string,
    file: File | null,
    setFieldValue: (field: string, value: any) => void,
    title: string
  ) => {
    if (file) {
      try {
        const data = await uploadImage(file, "business", title);
        const imageUrl = data?.uploadImage?.url;
        if (imageUrl) {
          setFieldValue(field, imageUrl);
        }
      } catch (error) {
        toast.error("Error uploading image. Please try again.");
      }
    } else {
      setFieldValue(field, null);
    }
  };

  const cleanUpTimeString = (time: string): string => {
    return time
      .replace(/\s*(AM|PM)\s*/gi, " $1")
      .replace(/(AM|PM)\s*(AM|PM)+/gi, "$1")
      .trim();
  };

  const formatTimeToAmPm = (time: string): string => {
    const cleanedTime = cleanUpTimeString(time);
    const [hourPart, minutePartWithAmPm] = cleanedTime.split(":");

    if (!hourPart || !minutePartWithAmPm) {
      throw new Error(`Invalid time format: ${time}`);
    }

    const hours = parseInt(hourPart, 10);
    const minutePart = minutePartWithAmPm.slice(0, 2);
    const isPm = cleanedTime.toUpperCase().includes("PM");
    const ampm = isPm ? "PM" : "AM";

    const formattedHour = hours % 12 || 12;
    return `${formattedHour}:${minutePart} ${ampm}`;
  };

  const handleSubmit = async (values: FormValues) => {
    try {
      setIsLoading(true);
      const images = await Promise.all(
        gallaryImages.map(async (image) => {
          if (image.startsWith("blob:")) {
            const uploadedImages = await uploadImages(
              [image],
              "business",
              values?.businessName
            );
            return uploadedImages?.uploadMultipleImages?.[0];
          }
          return image;
        })
      );
      // Handle overview
      const overview = values.overview;

      // Clean addresses by removing `__typename`
      if (values?.addresses) {
        values.addresses = values.addresses.map(
          ({ __typename, ...addressWithoutTypename }) => addressWithoutTypename
        );
      }

      // Clean timing by removing `__typename`
      if (timings) {
        values.timing = timings.map(
          ({ __typename, startTime, endTime, ...rest }) => ({
            ...rest,
            startTime: startTime ? formatTimeToAmPm(startTime) : "",
            endTime: endTime ? formatTimeToAmPm(endTime) : "",
          })
        );
      }

      // Clean social media by removing `__typename`
      if (values?.socialMedia) {
        values.socialMedia = values.socialMedia.map(
          ({ __typename, ...socialMediaWithoutTypename }) =>
            socialMediaWithoutTypename
        );
      }
      let brandlogoImageUrl = mainImageUrl;
      if (mainImageFile) {
        const res = await uploadImage(
          mainImageFile,
          "businessLogo",
          values.businessName
        );
        brandlogoImageUrl = res?.uploadImage?.url;
      }

      // Prepare variables for the mutation
      const variables: any = {
        input: {
          ...values,
          isPromoted: values?.isPromoted,
          brandLogo: brandlogoImageUrl,
          timing: values?.timing,
          overview,
          documents: [], // Empty documents for now
          gallery: { image: images }, // Use the uploaded or existing gallery images
          addresses: values?.addresses?.map((address) => ({
            ...address,
            pinCode: address.pinCode, // Ensuring pinCode is sent in correct format
          })),
        },
      };

      // Conditionally include owner details
      if (showOwnerDetails) {
        variables.input.owners = {
          firstName: values?.owners?.firstName || "",
          lastName: values?.owners?.lastName || "",
          mobileNumber: values?.owners?.mobileNumber || "",
          email: values?.owners?.email || "",
          designation: values?.owners?.designation || "",
        };
      } else {
        // Remove owner details if `showOwnerDetails` is false
        const { owners, ...rest } = variables.input;
        variables.input = { ...rest };
      }

      // Destructure primarySubCategory out of variables
      const { ...rest } = variables?.input;

      // Perform the mutation for update or create
      const res = business
        ? await updateBusinessProfile({
            variables: {
              _id: business?._id,
              input: rest,
            },
          })
        : await createBusinessProfile({ variables });
      // Handle success responses
      if (
        res?.data?.createBusinessProfile?.statusCode === 200 ||
        res?.data?.updateBusinessProfileDetails?.statusCode === 200
      ) {
        toast.success(
          res?.data?.createBusinessProfile?.message ||
            res?.data?.updateBusinessProfileDetails?.message ||
            "Business profile added successfully"
        );
        // fetchBusinessProfile();
        const updatedData = await fetchBusinessProfile({
          variables: { _id: business?._id },
        });

        setBusiness(updatedData?.data?.getBusinessProfilebyslug?.data);
        onClose;
      } else {
        throw new Error(
          (res?.data?.updateBusinessProfileDetails?.message as any) ||
            (res?.data?.createBusinessProfile?.message as any)
        );
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTimingChange = (
    index: number,
    field: keyof any,
    value: string
  ) => {
    const updatedTimings = [...timings];
    updatedTimings[index] = { ...updatedTimings[index], [field]: value };
    setTimings(updatedTimings);
  };

  const addNewTimingField = () => {
    if (timings.length < 7) {
      setTimings([...timings, { day: "", startTime: "", endTime: "" }]);
    }
  };
  const removeTimingField = (index: number) => {
    const updatedTimings = timings.filter((_, i) => i !== index);
    setTimings(updatedTimings);
  };
  const usedDays = timings.map((timing) => timing.day);

  const handleAddBannerImage = () => {
    setGallaryImages([...gallaryImages, null]);
  };

  const handleRemoveBannerImage = (index: number) => {
    const updatedBannerImages = [...gallaryImages];
    updatedBannerImages.splice(index, 1);
    setGallaryImages(updatedBannerImages);
  };
  //BrndLogo
  const handleMainImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file) {
      setMainImageFile(file);
      setMainImageUrl(URL.createObjectURL(file));
    }
  };

  //Banner Image function
  const handleBannerImageChange = async (
    index: number,
    files: FileList | null,
    imageUrl: string | null,
    setFieldValue: (field: string, value: any) => void,
    title: string
  ) => {
    if (files) {
      const newFiles = Array.from(files);
      const uploadedImages = await uploadImages(newFiles, "business", title);
      const uploadedUrls = uploadedImages?.uploadMultipleImages?.map(
        (image: any) => image?.url
      );
      const updatedGalleryImages = [...gallaryImages];
      updatedGalleryImages[index] =
        uploadedUrls[0] || updatedGalleryImages[index];

      setGallaryImages(updatedGalleryImages);
    } else if (imageUrl?.startsWith("https")) {
      // If a valid URL is provided, use it directly
      const updatedGalleryImages = [...gallaryImages];
      updatedGalleryImages[index] = imageUrl;

      setGallaryImages(updatedGalleryImages);
      setFieldValue(`gallery.image[${index}]`, imageUrl);
    }
  };

  const steps = [
    "Basic Information",
    "Address Details",
    "Business Timings",
    "Gallery & Media",
  ];
  return (
    <Box>
      <Box p={2}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          {/* <img
            src={backButton}
            alt="backButton"
            height={25}
            width={25}
            style={{ marginRight: "12px", cursor: "pointer" }}
            onClick={onClose}
          /> */}
          <Typography
            variant="h4"
            gutterBottom
            style={{ color: "#00C5B9" }}
            marginTop={2}
          >
            {business ? "Update Business" : "Create Business"}
          </Typography>
        </Box>
        <Divider />
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{ marginY: "12px" }}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  // if (index < activeStep) {
                  //   setActiveStep(index);
                  // } else {
                  //   toast.error("You can not move forward on greater page!");
                  // }
                  setActiveStep(index);
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, setFieldValue }: any) => {
            useEffect(() => {
              if (values?.addresses) {
                values?.addresses?.forEach((address: any, index: number) => {
                  setFieldValue(
                    `addresses.${index}.pinCode`,
                    address?.pinCode?._id
                  );
                });
              }
            }, [initialValues, setFieldValue]);
            return (
              <Form>
                {/* Step 1: Business Basic Information */}
                {/* Business Name */}

                {activeStep === 0 && (
                  <BasicInfo
                    values={values}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    touched={touched}
                    onClose={onClose}
                    handleNext={() => handleNext(values)}
                    showOwnerDetails={showOwnerDetails}
                    setShowOwnerDetails={setShowOwnerDetails}
                  />
                )}

                {/* after this will going on step 2 */}
                {/* Addresses */}
                {activeStep === 1 && (
                  <AddressInfo
                    values={values}
                    touched={touched}
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBack={handleBack}
                    setOverviewJson={setOverviewJson}
                    subCategoryData={subCategoryData}
                    setCategoryId={setCategoryId}
                    handleNext={() => handleNext(values)}
                  />
                )}

                {/* Timings Management */}
                {activeStep === 2 && (
                  <BusinessTIming
                    errors={errors}
                    setTimings={setTimings}
                    addNewTimingField={addNewTimingField}
                    timings={timings}
                    handleTimingChange={handleTimingChange}
                    removeTimingField={removeTimingField}
                    usedDays={usedDays}
                    overviewJson={overviewJson}
                    business={business}
                    categoryId={categoryId}
                    setFieldValue={setFieldValue}
                    handleBack={handleBack}
                    handleNext={() => handleNext(values)}
                  />
                )}

                {/* gallery and image section */}
                {activeStep === 3 && (
                  <BusinessImages
                    values={values}
                    businessImages={businessImages}
                    handleImageChange={handleImageChange}
                    setFieldValue={setFieldValue}
                    gallaryImages={gallaryImages}
                    handleDragStart={handleDragStart}
                    handleDragOver={handleDragOver}
                    handleBannerImageChange={handleBannerImageChange}
                    handleDrop={handleDrop}
                    handleRemoveBannerImage={handleRemoveBannerImage}
                    handleChange={handleChange}
                    handleAddBannerImage={handleAddBannerImage}
                    handleSubmit={handleSubmit}
                    handleBack={handleBack}
                    loading={isLoading}
                    handleMainImageChange={handleMainImageChange}
                    setMainImageUrl={setMainImageUrl}
                    setMainImageFile={setMainImageFile}
                    mainImageUrl={mainImageUrl}
                  />
                )}
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default BusinessModal;
