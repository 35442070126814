import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  InputAdornment,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { DELETE_CATEGORY, UPDATE_CATEGORY_RANK } from "src/graphql/mutations";
import { GET_CATEGORY, GET_CATEGORY_DATA } from "src/graphql/query";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "src/components/CustomButton";
import CategoryModal from "./CategoryModal";
import DeleteModel from "src/components/Common/DeleteModel";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ROUTES } from "src/constant";
import styles from "./Category.module.css";
import ToggleSwitch from "src/components/Common/ToggleSwitch";
import SaveIcon from "@mui/icons-material/Save";
import { ClearIcon } from "@mui/x-date-pickers";
import {
  DELETE_ALL_CATEGORIES,
  DELETE_MULTIPLE_CATEGORIES,
} from "src/graphql/DeleteMutation";
import { getRole, hasPermissionPage } from "src/components/Common/Utils";
import ActionMenu from "../Business/ActionMenu";
export interface Category {
  categoryType?: string;
  description?: string;
  _id: string;
  categoryName: string;
  businessProfileCount: string;
  imageUrl?: string;
  overviews?: any;
}

const Category: React.FC = () => {
  const [list, setList] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 0, pageSize: 50 });
  const [total, setTotal] = useState(0);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [deleteCategory] = useMutation(DELETE_CATEGORY);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState<string | null>(null);
  const [rankUpdates, setRankUpdates] = useState<{
    [key: string]: number | null;
  }>({});
  const [isRankEdit, setIsRankEdit] = useState<boolean>(false);
  const [UpdateCategoryRanksBulk] = useMutation(UPDATE_CATEGORY_RANK);
  const navigate = useNavigate();
  const [openDeleteMultipleModel, setOpenDeleteMultipleModel] =
    useState<boolean>(false);
  const [openDeleteAllModel, setOpenDeleteAllModel] = useState<boolean>(false);
  const [DeleteMultipleCategories] = useMutation(DELETE_MULTIPLE_CATEGORIES);
  const [DeleteAllCategories] = useMutation(DELETE_ALL_CATEGORIES);
  const [selectedRows, setSelectedRows] = useState([]);
  const isSuperAdmin = getRole() === "SuperAdmin";
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});
  
    const toggleExpand = (id: string) => {
      setExpandedRows((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
    };

  const COLUMNS = [
    ...(isSuperAdmin
      ? [
        {
          headerName: (
            <Box display={"flex"} alignItems={"center"}>
              <Checkbox
                checked={
                  selectedRows.length === list.length && list.length > 0
                }
                indeterminate={
                  selectedRows.length > 0 && selectedRows.length < list.length
                }
                onChange={(event) => handleSelectAll(event.target.checked)}
              />
            </Box>
          ),
          field: "select",
          width: 50,
          sortable: false,
          filterable: false,
          disableClickEventBubbling: true,
          renderCell: ({ row }: any) => (
            <Checkbox
              checked={selectedRows.includes(row?._id as never)}
              onChange={() => handleRowSelect(row._id)}
            />
          ),
        },
      ]
      : []),
    {
      headerName: "Category Name",
      field: "categoryName",
      width: 250,
      renderCell: ({
        row,
      }: {
        row: {
          categoryName: string;
          _id: string;
        };
      }) => (
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => handleRowClick(row?._id)}
        >
          {row?.categoryName}
        </Box>
      ),
    },
    {
      headerName: "Image",
      field: "imageUrl",
      width: 80,
      renderCell: ({ row }: { row: Category }) => (
        <Box display="flex" justifyContent="start" alignItems="center">
          {row.imageUrl ? (
            <img
              src={row.imageUrl}
              alt={row.categoryName}
              style={{ width: 40, height: 40, borderRadius: "50%" }}
            />
          ) : (
            <Typography variant="body2" color="textSecondary">
              No Image
            </Typography>
          )}
        </Box>
      ),
    },
      {
          headerName: "Description",
          field: "description",
          width: 430,
          renderCell: ({ value, row }: { value: string; row: any }) => {
            const expanded = expandedRows[row._id] || false;
            const maxLength = 50; // Adjust as needed
      
            return (
              <Box
                sx={{
                  whiteSpace: expanded ? "pre-wrap" : "nowrap",
                  overflow: "hidden",
                  textOverflow: expanded ? "clip" : "ellipsis",
                  width: "100%",
                }}
              >
                {expanded ? value : value.length > maxLength ? `${value.substring(0, maxLength)}...` : value}
                {value.length > maxLength && (
                  <span
                    onClick={(e) => {
                      e.stopPropagation(); // Prevents row selection
                      toggleExpand(row._id);
                    }}
                    style={{ color: "#00C5B9", cursor: "pointer", marginLeft: "10px"}}
                  >
                    {expanded ? "See Less" : "See More"}
                  </span>
                )}
              </Box>
            );
          },
        },

    { headerName: "Sub Category Count", field: "subCategoryCount", flex: 1 },
    {
      headerName: "Rank",
      field: "rank",
      flex: 1,
      renderCell: ({ row }: { row: any }) => {
        const [rankValue, setRankValue] = useState(row?.rank || "");

        const handleRankChange = (value: string) => {
          setRankValue(value);
          setRankUpdates((prev) => ({
            ...prev,
            [row._id]: value === "" ? null : Number(value),
          }));
        };

        return isRankEdit ? (
          <TextField
            value={rankValue}
            onChange={(e) => handleRankChange(e.target.value)}
            placeholder="Enter Rank"
            variant="outlined"
            size="small"
            style={{ marginRight: "8px" }}
          />
        ) : (
          <Typography
            display="flex"
            alignItems="center"
            style={{ height: "100%" }}
          >
            {rankValue}
          </Typography>
        );
      },
    },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: { _id: string } }) => (
        <div>
          {hasPermissionPage("Category", "update") && (
            <Tooltip title="Edit">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditMode(true);
                  handleEdit(row?._id);
                }}
                aria-label="edit"
                className={styles.editButton}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {hasPermissionPage("Category", "delete") && (
            <Tooltip title="Delete">
              <IconButton
                onClick={(e: any) => {
                  e.stopPropagation();
                  setDeleteCategoryId(row._id);
                  setOpenDeleteModel(true);
                }}
                aria-label="delete"
                className={styles.editButton}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const { loading, error, data, refetch } = useQuery(GET_CATEGORY, {
    variables: {
      page: pagination.page + 1,
      limit: pagination.pageSize,
    },
  });

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: pagination.page + 1,
        limit: pagination.pageSize,
      });
    }, 1000),
    [refetch, pagination.page, pagination.pageSize]
  );

  useEffect(() => {
    if (data?.getCategories?.data) {
      setList(data.getCategories.data);
    }
    setTotal(data?.getCategories?.count);
  }, [data, refetch]);

  const [fetchCategoryData] = useLazyQuery(GET_CATEGORY_DATA);

  const handleEdit = async (rowData: any) => {
    const res = await fetchCategoryData({
      variables: {
        _id: rowData,
      },
    });
    setSelectedCategory(res.data?.getCategory?.data);
    setOpenModal(true);
  };
  const handleRowSelect = (id: any) => {
    setSelectedRows((prevSelectedRows: any) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId: any) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };
  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      const allRowIds = list.map((row: any) => row._id);
      setSelectedRows(allRowIds as any);
    } else {
      setSelectedRows([]);
    }
  };

  const handleMultipleDelete = async () => {
    try {
      const res = await DeleteMultipleCategories({
        variables: {
          _id: selectedRows,
        },
      });
      if (res?.data?.deleteMultipleCategories?.statusCode === 200) {
        toast.success(res?.data?.deleteMultipleCategories?.message);
        refetch();
      } else {
        throw new Error(res?.data?.deleteMultipleCategories?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setOpenDeleteMultipleModel(false);
    }
  };

  const handleAllDelete = async () => {
    try {
      const res = await DeleteAllCategories();
      if (res?.data?.deleteAllCategories?.statusCode === 200) {
        toast.success(res?.data?.deleteAllCategories?.message);
        refetch();
      } else {
        throw new Error(res?.data?.deleteAllCategories?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setOpenDeleteAllModel(false);
    }
  };

  const handleDelete = async () => {
    if (!deleteCategoryId) return;
    try {
      const res = await deleteCategory({
        variables: { id: deleteCategoryId },
      });
      if (res?.errors) {
        throw new Error(res.errors as any);
      } else {
        setOpenDeleteModel(false);
        refetch();
        toast.success(res?.data?.deleteCategory?.message);
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedCategory(null);
    refetch({
      search: searchTerm,
      page: pagination.page > 0 ? pagination.page : 1,
      limit: pagination.pageSize,
    });
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
    refetch({
      search: searchTerm,
      page: newPage + 1,
      limit: pagination.pageSize,
    });
  };

  const handleRowClick = (id: string) => {
    navigate(`${ROUTES.CATEGORY}/${id}`);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page + 1,
      limit: newPageSize,
    });
  };

  const handleRankUpdate = async () => {
    const input = Object.keys(rankUpdates).map((id) => ({
      categoryId: id,
      rank: rankUpdates[id],
    }));

    try {
      const response = await UpdateCategoryRanksBulk({
        variables: { input: { categories: input } },
      });
      if (response?.data?.updateCategoryRanksBulk?.statusCode === 200) {
        toast.success(response.data.updateCategoryRanksBulk.message);
        setRankUpdates({});
      } else {
        throw new Error(response.data.updateCategoryRanksBulk.message);
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsRankEdit(false);
      refetch();
    }
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({ search: "", page: 1, limit: pagination.pageSize });
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box p={3}>
      {!openModal && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            paddingRight={4}
          >
            <Typography variant="h4" className={styles.primaryColor}>
              Categories
            </Typography>

            <Box display="flex" alignItems="center" gap={2}>
              <ToggleSwitch mode={isRankEdit} setMode={setIsRankEdit} />
              <Box width={250}>
                <TextField
                  label="Search Categories"
                  variant="outlined"
                  size="small"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  InputProps={{
                    endAdornment: searchTerm && (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClearSearch}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              {isSuperAdmin && 
              <ActionMenu
                isSuperAdmin={isSuperAdmin}
                setOpenDeleteMultipleModel={setOpenDeleteMultipleModel}
                setOpenDeleteAllModel={setOpenDeleteAllModel}
              />
      }
              {isRankEdit && (
                <Tooltip title="Save">
              <Box width={50} mr={2}>
                <CustomButton
                      onClick={handleRankUpdate}
                      variant="contained"
                      sx={{ width: 50 }}
                    >
                      <SaveIcon />
                    </CustomButton>
                  </Box>
            </Tooltip>
          )}
              {hasPermissionPage("Category", "add") && (
                <Box>
                  <CustomButton
                    onClick={() => {
                      setIsEditMode(false);
                      setOpenModal(true);
                    }}
                    variant="contained"
                    sx={{
                      backgroundColor: "#00C5B9",
                      "&:hover": { backgroundColor: "#009B8C" },
                    }}
                  >
                    Create
                  </CustomButton>
                </Box>
              )}
            </Box>
          </Box>
          <Divider />
          <Box mt={2}>
            <CustomTable
              columns={COLUMNS}
              data={list}
              paginationModel={{
                page: pagination.page,
                pageSize: pagination.pageSize,
              }}
              totalCount={total}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              getRowHeight={({ id }) => (expandedRows[id] ? "auto" : null)}
            />
          </Box>
        </>
      )}
      <CategoryModal
        open={openModal}
        handleClose={handleCloseModal}
        category={selectedCategory}
        refetchCategory={refetch}
        isEditMode={isEditMode}
      />
      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={() => setOpenDeleteModel(false)}
          onConfirm={handleDelete}
          message="Are you sure you want to delete this category?"
        />
      )}
      {openDeleteMultipleModel && (
        <DeleteModel
          open={openDeleteMultipleModel}
          onClose={() => {
            setOpenDeleteMultipleModel(false);
          }}
          onConfirm={handleMultipleDelete}
          message={`Are you sure you want to delete ${selectedRows?.length ?? 0
            } categories?`}
        />
      )}
      {openDeleteAllModel && (
        <DeleteModel
          open={openDeleteAllModel}
          onClose={() => {
            setOpenDeleteAllModel(false);
          }}
          onConfirm={handleAllDelete}
          message="Are you sure you want to delete all categories?"
        />
      )}
    </Box>
  );
};

export default Category;
