import React, { useEffect, useState } from "react";
import {
  TextField,
  Grid,
  Typography,
  FormControl,
  Box,
  FormControlLabel,
  Checkbox,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  GET_APPROVED_BUSINESS,
  GET_BUSINESS,
  GET_DEALS_ENUM_ALL,
} from "src/graphql/query";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import BusinessSelectDropDown from "src/components/Common/BusinessSelectDropDown";
import CustomButton from "src/components/CustomButton";
import Select from "react-select";
import {
  DealCategory,
  dealCategoryOptions,
  OfferType,
  offerTypeOptions,
  RepeatOption,
  repeatOptions,
  weekDays,
} from "./dealCategory";
import { CREATE_DEAL, UPDATE_DEAL } from "src/graphql/mutations";
import { uploadImage } from "src/components/Common/Utils";
import { toast } from "react-toastify";
import moment from "moment";
import backButton from "../../Images/backButton.svg";
import { debounce } from "src/utils/debounce";
import RichTextEditor from "src/components/Common/TextEditor";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";

interface BusinessOptions {
  value: string;
  label: string;
}

const CreateDeal = ({ reload, dealData, onClose }: any) => {
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [businessOptions, setBusinessOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState<BusinessOptions[]>([]);
  const [dealCategories, setDealCategories] = useState<any>([]);
  const [AddDeals] = useMutation(CREATE_DEAL);
  const [UpdateDeals] = useMutation(UPDATE_DEAL);

  const formic = useFormik({
    initialValues: {
      businessProfileId: "",
      title: "",
      isPromoted: false,
      image: "",
      category: "",
      area: "",
      offer: "",
      offerType: "",
      // discountBracket: { minimum: "", maximum: "" },
      discountBracket: { minimum: null, maximum: null },
      duration: { startDate: "", endDate: "" },
      repeatDuration: { repeat: "", weekDays: [] },
      description: "",
      termsAndConditions: "",
      bookingLinks: "",
      contactNo: "",
      metaTitle: "",
      metaDescription: "",
      focus: "",
      timing: [{ name: "", time: "", price: "" }]
    },
    validationSchema: Yup.object({
      businessProfileId: Yup.string().required("Business Profile Required"),
      title: Yup.string().required("Title is required"),
      category: Yup.string().required("Category is required"),
      area: Yup.string().required("Area is required"),
      offer: Yup.string().required("Offer is Required"),
      offerType: Yup.string().required("Discount is required"),
      image: Yup.mixed().required("Image is required"),
      repeatDuration: Yup.object({
        repeat: Yup.string().required("Repeat option is required"),
        weekDays: Yup.array().when("repeat", {
          is: (val: any) => val && val !== RepeatOption.DOES_NOT_REPEAT,
          then: (schema) =>
            schema.min(1, "At least one weekday must be selected"),
          otherwise: (schema) => schema.notRequired(),
        }),
      }),

      // discountBracket: Yup.lazy(() => {
      //   return Yup.object().shape({
      //     minimum: Yup.number().when("offerType", {
      //       is: (val: OfferType) => val === OfferType.Percentage,
      //       then: (schema) => schema.required("Minimum is required"),
      //       otherwise: (schema) => schema.notRequired(),
      //     }),
      //     maximum: Yup.number().when("offerType", {
      //       is: (val: OfferType) => val === OfferType.Percentage,
      //       then: (schema) => schema.required("Maximum is required"),
      //       otherwise: (schema) => schema.notRequired(),
      //     }),
      //   });
      // }),
      // discountBracket: Yup.lazy(() => {
      //   return Yup.object().shape({
      //     minimum: Yup.number()
      //       .nullable() // Allow null values
      //       .when("offerType", {
      //         is: (val: OfferType) => val === OfferType.Percentage,
      //         then: (schema) => schema.required("Minimum is required"),
      //         otherwise: (schema) => schema.notRequired(),
      //       }),
      //     maximum: Yup.number()
      //       .nullable() // Allow null values
      //       .when("offerType", {
      //         is: (val: OfferType) => val === OfferType.Percentage,
      //         then: (schema) => schema.required("Maximum is required"),
      //         otherwise: (schema) => schema.notRequired(),
      //       }),
      //   });
      // }),
      discountBracket: Yup.object({
        minimum: Yup.number()
          .nullable()
          .when("offerType", {
            is: OfferType.Percentage,
            then: (schema) =>
              schema
                .required("Minimum is required")
                .typeError("Must be a number"),
          }),
        maximum: Yup.number()
          .nullable()
          .when("offerType", {
            is: OfferType.Percentage,
            then: (schema) =>
              schema
                .required("Maximum is required")
                .typeError("Must be a number"),
          }),
        otherwise: Yup.object().nullable(),
      }),

      duration: Yup.object({
        startDate: Yup.date().required("Start date is required"),
        endDate: Yup.date().required("End date is required"),
      }),
      description: Yup.string().required("Description required"),
      termsAndConditions: Yup.string().required(
        "Terms and Conditions is required"
      ),
      metaTitle: Yup.string().required("Meta title is required"),
      metaDescription: Yup.string().required("Meta description is required"),
      focus: Yup.string().required("Focus keyword is required"),
      contactNo: Yup.string()
        .nullable()
        .matches(/^[0-9]+$/, "Please Enter numbers")
        .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits")
        .notRequired(),
      bookingLinks: Yup.string()
        .nullable()
        .matches(
          /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
          "Enter a valid URL"
        )
        .notRequired(),
      timing: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().nullable().notRequired(),
          time: Yup.string()
            .nullable()
            .matches(
              /^([0-9]{1,2}):([0-9]{2}) (AM|PM) - ([0-9]{1,2}):([0-9]{2}) (AM|PM)$/,
              'Enter a valid time range (e.g., 09:00 AM - 06:00 PM)'
            )
            .notRequired(),
          price: Yup.number()
            .nullable()
            .typeError('Price must be a number')
            .positive('Price must be a positive number')
            .notRequired(),
        })
      ),
    }),
    onSubmit: () => handleSubmit(),
  });

  const { data: dealCategory, error } = useQuery(GET_DEALS_ENUM_ALL, {
    onError: (err) => {
      toast.error("Facing issue in fetching categories");
      console.error("Query error:", err);
    },
    onCompleted: (data) => {
      const dealCategories = data?.getAllDealsEnum?.data ?? [];
      const optionObj = dealCategories?.map((item: any) => ({
        value: item?.name,
        label: item?.name,
      }));
      setDealCategories(optionObj);
    },
  });

  const handleClose = () => {
    formic.resetForm();
    setImageFile(null);
    setImagePreview(null);
    setSearchTerm("");
    onClose();
  };

  // Lazy query to fetch businesses based on search
  const [getBusinesses, { data, loading }] = useLazyQuery(GET_BUSINESS, {
    variables: { search: searchTerm },
    fetchPolicy: "network-only",
  });

  const handleSearchTermChange = debounce((term: string) => {
    if (term.length >= 3) {
      setSearchTerm(term);
      getBusinesses({ variables: { search: term } });
    } else {
      setSearchTerm(""); 
      setBusinessOptions([]);
      setAreaOptions([]); 
    }
  }, 300);

  useEffect(() => {
    if (
      data &&
      data?.getBusinessProfilesAll &&
      data?.getBusinessProfilesAll?.data
    ) {
      setBusinessOptions(
        data?.getBusinessProfilesAll?.data?.map((business: any) => ({
          value: business._id,
          label: `${business?.businessName} (${business?.pinCodeDetails})`,
        }))
      );
    }
  }, [data]);

  useEffect(() => {
    if (formic?.values?.businessProfileId && data?.getBusinessProfilesAll?.data) {
      const selectedBusiness = data?.getBusinessProfilesAll?.data?.find(
        (business: any) => business?._id === formic?.values?.businessProfileId
      );

      if (selectedBusiness) {
        const updatedAreaOptions =
          selectedBusiness?.pinCodeDetails?.map((pinCode: string) => ({
            value: pinCode,
            label: pinCode,
          })) || [];
        setAreaOptions(updatedAreaOptions);

        // Set default area if not already set
        if (!formic?.values?.area && updatedAreaOptions?.length > 0) {
          formic.setFieldValue("area", updatedAreaOptions[0]?.value);
        }
      }
    }
  }, [formic?.values?.businessProfileId, data]);

  const handleSubmit = async () => {
    let imageUrl = formic?.values?.image;
    if (imageFile) {
      const Name = businessOptions?.filter(
        (data: any) => data?.value === formic?.values?.businessProfileId
      ) as any;
      const res = await uploadImage(
        imageFile,
        `business/${Name?.[0]?.label as any}/deals`,
        formic?.values?.title
      );
      imageUrl = res?.uploadImage?.url;
    }

    const payload: any = {
      ...formic?.values,
      image: imageUrl,
    };

    if (formic?.values?.offerType === OfferType.Flat) {
      delete payload.discountBracket;
    }

    try {
      const response = dealData
        ? await UpdateDeals({
          variables: {
            _id: dealData?._id,
            input: payload,
          },
        })
        : await AddDeals({
          variables: {
            input: payload,
          },
        });

      if (response?.errors) {
        throw new Error(response?.errors as any);
      } else {
        toast.success(response?.data?.addDeals?.message || response?.data?.updateDeals?.message || "Deals");
        reload();
        formic.handleReset;
        handleClose();
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
      formic.setFieldValue("image", file.name);
    }
  };
  const offerText =
    formic?.values?.offerType === OfferType.Percentage
      ? `${formic?.values?.discountBracket?.minimum}%-${formic?.values?.discountBracket?.maximum}% OFF`
      : formic?.values?.offer;

  useEffect(() => {
    formic.setValues((prev: any) => ({
      ...prev,
      offer: offerText,
    }));
  }, [offerText]);
  useEffect(() => {
    if (dealData) {
      formic.setValues({
        businessProfileId: dealData?.businessProfileId || "",
        title: dealData?.title || "",
        isPromoted: dealData?.isPromoted || false,
        image: dealData?.image || "",
        category: dealData?.category || "",
        area: dealData?.area || "",
        offer: dealData?.offer || "",
        offerType: dealData?.offerType || "",
        discountBracket: dealData?.discountBracket
          ? {
            minimum: dealData?.discountBracket?.minimum,
            maximum: dealData?.discountBracket?.maximum,
          }
          : {
            minimum: "",
            maximum: "",
          },
        duration: dealData?.duration
          ? {
            startDate: moment(dealData?.duration?.startDate).format(
              "YYYY-MM-DD"
            ),
            endDate: moment(dealData?.duration?.endDate).format("YYYY-MM-DD"),
          }
          : { startDate: "", endDate: "" },
        repeatDuration: dealData?.repeatDuration
          ? {
            repeat: dealData?.repeatDuration?.repeat,
            weekDays: dealData?.repeatDuration?.weekDays,
          }
          : {
            repeat: "",
            weekDays: [],
          },

        description: dealData?.description || "",
        termsAndConditions: dealData?.termsAndConditions || "",
        bookingLinks: dealData?.bookingLinks || "",
        contactNo: dealData?.contactNo || "",
        metaTitle: dealData?.metaTitle || "",
        metaDescription: dealData?.metaDescription || "",
        focus: dealData?.focus || "",
        timing: dealData?.timing?.length
          ? dealData?.timing?.map((item: any) => ({
            name: item?.name || "",
            time: item?.time || "",
            price: item?.price || "",
          }))
          : [{ name: "", time: "", price: "" }],
      });
      if (dealData?.businessProfile) {
        const businessOption = {
          value: dealData?.businessProfileId,
          label: `${dealData?.businessProfile?.businessName} (${dealData?.businessProfile?.pinCodeDetails})`,
        };
        setBusinessOptions([businessOption] as any);

        if (dealData?.businessProfile?.pinCodeDetails?.length > 0) {
          const updatedAreaOptions = dealData?.businessProfile?.pinCodeDetails?.map(
            (pinCode: string) => ({
              value: pinCode,
              label: pinCode,
            })
          );
          setAreaOptions(updatedAreaOptions);

          // Set area field value to the first pincode if not set
          if (!formic?.values?.area) {
            formic.setFieldValue("area", updatedAreaOptions[0]?.value);
          }
        }
      }

    }
  }, [dealData]);

  const handleBusinessChange = (selectedOption: any | null) => {
    if (!selectedOption) {
      formic.setFieldValue("businessProfileId", "");
      setSearchTerm(""); // Clear the search term
      setBusinessOptions([]); // Reset the business options
      formic.setFieldValue("area", "");
      return;
    }

    formic.setFieldValue("businessProfileId", selectedOption?.value);
    setSearchTerm(selectedOption?.label);

    const selectedBusiness = data?.getBusinessProfilesAll?.data?.find(
      (business: any) => business?._id === selectedOption?.value
    );

    if (selectedBusiness && selectedBusiness?.pinCodeDetails?.length > 0) {
      formic.setFieldValue("area", selectedBusiness?.pinCodeDetails[0]); // Default to the first available area
    } else {
      formic.setFieldValue("area", "");
    }
  };

  const generateSlug = (label: string) => {
    const cleanedLabel = label?.replace(/\(.*\)/g, "").trim();
    return cleanedLabel
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/[^\w-]/g, "") // Remove special characters
      .replace(/^-+|-+$/g, ""); // Remove leading/trailing hyphens
  };

  const selectedBusiness = businessOptions?.find(
    (option: { value: string; label: string }) => option?.value === formic?.values?.businessProfileId
  ) as { value: string; label: string } | undefined;

  const businessSlug = selectedBusiness ? generateSlug(selectedBusiness.label) : null;

  const handleViewBusiness = () => {
    if (selectedBusiness && businessSlug) {
      window.open(`/business/${businessSlug}`, "_blank");
    }
    else {
      console.error("No business found or slug could not be generated.");
    }
  };

  const handleEditViewBusiness = () => {
    // Check if dealData exists, and use the businessProfile from it if so
    const businessToView = dealData
      ? dealData.businessProfile
      : selectedBusiness; // This will ensure we always have the business to view

    if (businessToView) {
      const businessSlug = generateSlug(businessToView?.businessName);
      window.open(`/business/${businessSlug}`, "_blank");
    } else {
      console.error("No business found or slug could not be generated.");
    }
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedStartDate = new Date(e.target.value);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (selectedStartDate < today) {
      formic.setFieldValue("duration.startDate", "");
    } else {
      formic.setFieldValue("duration.startDate", e.target.value);
      if (
        formic?.values?.duration?.endDate &&
        new Date(formic?.values?.duration?.endDate) < selectedStartDate
      ) {
        formic.setFieldValue("duration.endDate", "");
      }
    }
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedEndDate = new Date(e.target.value);
    const startDate = new Date(formic?.values?.duration.startDate);

    if (startDate && selectedEndDate < startDate) {
      formic.setFieldValue("duration.endDate", "");
    } else {
      formic.setFieldValue("duration.endDate", e.target.value);
    }
  };

  const handleAddTiming = () => {
    const newTiming = [
      ...formic?.values?.timing,
      { name: "", time: "", price: "" },
    ];
    formic.setFieldValue("timing", newTiming);
  };

  const handleRemoveTiming = (index: number) => {
    const newTiming = formic?.values?.timing.filter(
      (_: any, i: number) => i !== index
    );
    formic.setFieldValue("timing", newTiming);
  };

  return (
    <Box p={2}>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <img
          src={backButton}
          alt="backButton"
          height={25}
          width={25}
          style={{ marginRight: "12px", cursor: "pointer" }}
          onClick={handleClose}
        />
        <Typography
          variant="h4"
          gutterBottom
          style={{ color: "#00C5B9" }}
          marginTop={2}
        >
          {dealData ? "Update Deal" : "Create Deal"}
        </Typography>
      </Box>
      <form onSubmit={formic.handleSubmit}>
        <Grid container spacing={2}>
          {/* Existing form fields */}
          <Grid item xs={12} sm={6}>
            <Select
              isClearable
              placeholder={loading ? "Loading..." : "Select a Business"}
              value={
                businessOptions.find(
                  (option: any) => option?.value === formic?.values?.businessProfileId
                ) || null
              }
              onChange={handleBusinessChange}
              onInputChange={(value, actionMeta) => {
                if (actionMeta.action === "input-change") {
                  if (value === "") {
                    setSearchTerm(""); // Clear search and prevent API call
                    setBusinessOptions([]); // Clear dropdown
                  } else {
                    handleSearchTermChange(value);
                  }
                }
              }}
              options={businessOptions}
            />

            {formic?.touched?.businessProfileId &&
              formic?.errors?.businessProfileId && (
                <Typography variant="body2" color="error">
                  {formic?.errors?.businessProfileId}
                </Typography>
              )}
            {selectedBusiness && (
              <Box
                display="flex"
                justifyContent="start"
                alignItems="center"
                sx={{ cursor: "pointer" }}
                onClick={handleViewBusiness}
              >
                <Box display={"flex"} gap={1} alignItems={"center"} mb={1}>
                  <Typography variant="body2" color="textSecondary">
                    View this business : {selectedBusiness?.label}
                  </Typography>
                </Box>
              </Box>
            )}
            {dealData && !selectedBusiness && (
              <Box
                display="flex"
                justifyContent="start"
                alignItems="center"
                sx={{ cursor: "pointer" }}
                onClick={handleEditViewBusiness}
              >
                <Box display={"flex"} gap={1} alignItems={"center"} mb={1}>
                  <Typography variant="body2" color="textSecondary">
                    View this business : {dealData?.businessProfile?.businessName} ({dealData?.businessProfile?.pinCodeDetails})
                  </Typography>
                </Box>
              </Box>
            )}
          </Grid>

          {/* Category and other fields */}
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={formic?.touched?.category && Boolean(formic?.errors?.category)}
              sx={{ zIndex: 99999 }}
            >
              <Select
                options={dealCategories}
                value={dealCategories?.find(
                  (option: any) => option?.value === formic?.values?.category
                )}
                isClearable
                onChange={(selectedOption: any) => {
                  const value = selectedOption ? selectedOption?.value : "";
                  formic.setFieldValue("category", value as DealCategory);
                }}
                placeholder="Select Category"
              />
              {formic?.touched?.category && formic?.errors?.category && (
                <Typography variant="body2" color="error">
                  {formic?.errors?.category}
                </Typography>
              )}
            </FormControl>
          </Grid>

          {/* Other fields */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Title"
              name="title"
              value={formic?.values?.title}
              onChange={formic.handleChange}
              onBlur={formic.handleBlur}
              error={formic?.touched?.title && Boolean(formic?.errors?.title)}
              helperText={
                formic?.touched?.title && formic?.errors?.title
                  ? formic?.errors?.title
                  : ""
              }
            />
          </Grid>

          {/* Area */}
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={formic?.touched?.area && Boolean(formic?.errors?.area)}
            >
              <Select
                options={areaOptions}
                onChange={(option) => {
                  formic.setFieldValue("area", option?.value);
                }}
                placeholder="Select Area"
                value={
                  areaOptions?.find(
                    (option) => option?.value === formic?.values?.area
                  )}
                isClearable
              />
              {formic?.touched?.area && formic?.errors?.area && (
                <div style={{ color: "red" }}>{formic?.errors?.area}</div>
              )}
            </FormControl>
          </Grid>
          {/* Start Date Field */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Start Date"
              name="duration.startDate"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={formic?.values?.duration?.startDate || ""}
              onChange={handleStartDateChange}
              onBlur={formic.handleBlur}
              error={
                formic?.touched?.duration?.startDate &&
                Boolean(formic?.errors?.duration?.startDate)
              }
              helperText={
                formic?.touched?.duration?.startDate &&
                formic?.errors?.duration?.startDate
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* End Date Field */}
            <TextField
              fullWidth
              label="End Date"
              name="duration.endDate"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={formic?.values?.duration?.endDate || ""}
              inputProps={{
                min:
                  formic?.values?.duration?.startDate ||
                  new Date().toISOString().split("T")[0],
              }}
              onChange={handleEndDateChange}
              onBlur={formic.handleBlur}
              error={
                formic?.touched?.duration?.endDate &&
                Boolean(formic?.errors?.duration?.endDate)
              }
              helperText={
                formic?.touched?.duration?.endDate &&
                formic?.errors?.duration?.endDate
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth margin="normal">
              <FormControlLabel
                control={
                  <Checkbox
                    name="isPromoted"
                    checked={formic?.values?.isPromoted}
                    onChange={formic?.handleChange}
                  />
                }
                label="Is Promoted"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography>Description:</Typography>
            <RichTextEditor
              value={formic?.values?.description}
              onChange={(content: string) =>
                formic.setFieldValue("description", content)
              }
            />
            {formic?.touched?.description && formic?.errors?.description && (
              <Typography color="error">{formic?.errors?.description}</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography>Terms and Conditions:</Typography>
            <RichTextEditor
              value={formic?.values?.termsAndConditions}
              onChange={(content: string) =>
                formic.setFieldValue("termsAndConditions", content)
              }
            />
            {formic?.touched?.termsAndConditions &&
              formic?.errors?.termsAndConditions && (
                <Typography color="error">
                  {formic?.errors?.termsAndConditions}
                </Typography>
              )}
          </Grid>

          {/* Offer, Offer Type, and Discount Bracket fields */}
          <Grid item xs={12}>
            <Typography variant="h6">Offer:</Typography>
            <Typography>{offerText}</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              error={
                formic?.touched?.offerType && Boolean(formic?.errors?.offerType)
              }
              sx={{ zIndex: 99999 }}
            >
              <Select
                options={offerTypeOptions}
                value={offerTypeOptions.find(
                  (option) => option?.value === formic?.values?.offerType
                )}
                isClearable
                onChange={(selectedOption: any) => {
                  const value = selectedOption ? selectedOption?.value : "";
                  formic.setFieldValue("offerType", value as OfferType);
                  if (value === OfferType.Percentage) {
                    formic.setFieldValue("discountBracket", {
                      minimum: "",
                      maximum: "",
                    });
                  }
                }}
                placeholder="Select Offer Type"
              />
              {formic?.touched?.offerType && formic?.errors?.offerType && (
                <Typography variant="body2" color="error">
                  {formic?.errors?.offerType}
                </Typography>
              )}
            </FormControl>
          </Grid>
          {formic?.values?.offerType === OfferType.Percentage && (
            <>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Minimum Discount"
                  name="discountBracket.minimum"
                  type="number"
                  value={formic?.values?.discountBracket.minimum}
                  onChange={formic.handleChange}
                  onBlur={formic.handleBlur}
                  error={
                    formic?.touched?.discountBracket?.minimum &&
                    Boolean(formic?.errors?.discountBracket?.minimum)
                  }
                  helperText={
                    formic?.touched?.discountBracket?.minimum &&
                    formic?.errors?.discountBracket?.minimum
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Maximum Discount"
                  name="discountBracket.maximum"
                  type="number"
                  value={formic?.values?.discountBracket?.maximum}
                  onChange={formic.handleChange}
                  onBlur={formic.handleBlur}
                  error={
                    formic?.touched?.discountBracket?.maximum &&
                    Boolean(formic?.errors.discountBracket?.maximum)
                  }
                  helperText={
                    formic?.touched?.discountBracket?.maximum &&
                    formic?.errors?.discountBracket?.maximum
                  }
                />
              </Grid>
            </>
          )}
          {formic?.values?.offerType === OfferType.Flat && (
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Offer"
                name="offer"
                type="text"
                value={formic?.values?.offer}
                onChange={formic.handleChange}
                onBlur={formic.handleBlur}
                error={formic?.touched?.offer && Boolean(formic?.errors?.offer)}
                helperText={formic?.touched?.offer && formic?.errors?.offer}
              />
            </Grid>
          )}
          {/* Repeat options using react-select */}

          <Grid item xs={12}>
            <Typography variant="h6">Repeat Options:</Typography>
            <FormControl
              fullWidth
              sx={{ zIndex: 9999 }}
              error={
                formic?.touched?.repeatDuration?.repeat &&
                Boolean(formic?.errors?.repeatDuration?.repeat)
              }
            >
              <Select
                options={repeatOptions}
                value={repeatOptions?.find(
                  (option) =>
                    option?.value === formic?.values?.repeatDuration?.repeat
                )}
                isClearable
                onChange={(selectedOption: any) => {
                  const value = selectedOption ? selectedOption?.value : "";
                  formic.setFieldValue("repeatDuration.repeat", value);
                }}
                onBlur={() =>
                  formic.setFieldTouched("repeatDuration.repeat", true)
                }
                placeholder="Select Frequency"
              />
              {formic?.touched?.repeatDuration?.repeat &&
                formic?.errors?.repeatDuration?.repeat && (
                  <Typography color="error">
                    {formic?.errors?.repeatDuration?.repeat}
                  </Typography>
                )}
            </FormControl>
          </Grid>

          {/* Weekdays checkboxes using react-select */}
          {formic?.values?.repeatDuration?.repeat !==
            RepeatOption.DOES_NOT_REPEAT && (
              <Grid item xs={12}>
                <Typography variant="h6">Select Week Days:</Typography>
                <FormControl
                  sx={{ zIndex: "999" }}
                  error={
                    formic?.touched?.repeatDuration?.weekDays &&
                    Boolean(formic?.errors?.repeatDuration?.weekDays)
                  }
                >
                  <Select
                    options={weekDays}
                    isMulti
                    value={weekDays?.filter(
                      (day) =>
                        formic?.values?.repeatDuration?.weekDays?.includes(
                          day?.value as never
                        ) || ""
                    )}
                    onChange={(selectedOptions: any) => {
                      const selectedValues = selectedOptions
                        ? selectedOptions?.map((option: any) => option?.value)
                        : [];
                      formic.setFieldValue(
                        "repeatDuration.weekDays",
                        selectedValues
                      );
                    }}
                    onBlur={() =>
                      formic.setFieldTouched("repeatDuration.weekDays", true)
                    }
                    placeholder="Select Days"
                  />

                  {formic?.touched?.repeatDuration?.weekDays &&
                    formic?.errors?.repeatDuration?.weekDays && (
                      <Typography color="error">
                        {formic?.errors?.repeatDuration?.weekDays}
                      </Typography>
                    )}
                </FormControl>
              </Grid>
            )}

          {/* Image upload field */}
          <Grid item xs={12}>
            <Box>
              <Typography variant="body1">Deal Image:</Typography>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
                id="deal-image-upload"
              />
              <label htmlFor="deal-image-upload">
                <CustomButton variant="outlined" component="span">
                  Upload Image
                </CustomButton>
              </label>
              {formic?.touched?.image && formic?.errors?.image && (
                <Typography color="error">{formic?.errors?.image}</Typography>
              )}
              {(imagePreview || formic?.values?.image) && (
                <Box
                  sx={{
                    mt: 2,
                    width: "100%",
                    height: "auto",
                    borderRadius: 2,
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  <img
                    src={imagePreview ?? formic?.values?.image}
                    alt="Preview"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              )}
            </Box>
          </Grid>
          <Box marginTop={2} marginLeft={2}>
            <Typography variant="h6" gutterBottom>
              Timing Details
            </Typography>
            <Grid>
              {formic?.values?.timing.map((timingField: any, index: number) => (
                <Grid container item xs={12} key={index} spacing={2} alignItems="center">
                  <Grid item xs={3}>
                    <TextField
                      name={`timing.${index}.name`}
                      margin="dense"
                      label="Name"
                      fullWidth
                      value={timingField?.name}
                      onChange={formic.handleChange}
                      error={
                        formic?.touched?.timing?.[index]?.name &&
                        Boolean((formic?.errors?.timing as any)?.[index]?.name)
                      }
                      helperText={
                        formic?.touched?.timing?.[index]?.name &&
                        ((formic?.errors?.timing as any)?.[index]?.name as string)
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      name={`timing.${index}.time`}
                      margin="dense"
                      label="Time"
                      fullWidth
                      value={timingField?.time}
                      onChange={formic.handleChange}
                      error={
                        formic?.touched?.timing?.[index]?.time &&
                        Boolean((formic?.errors?.timing as any)?.[index]?.time)
                      }
                      helperText={
                        formic?.touched?.timing?.[index]?.time &&
                        ((formic?.errors?.timing as any)?.[index]?.time as string)
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      name={`timing.${index}.price`}
                      margin="dense"
                      label="Price"
                      fullWidth
                      value={timingField?.price}
                      onChange={formic.handleChange}
                      error={
                        formic?.touched?.timing?.[index]?.price &&
                        Boolean((formic?.errors?.timing as any)?.[index]?.price)
                      }
                      helperText={
                        formic?.touched?.timing?.[index]?.price &&
                        ((formic?.errors?.timing as any)?.[index]?.price as string)
                      }
                    />
                  </Grid>
                  <Grid item xs={1} style={{ display: "flex", justifyContent: "center" }}>
                    <Tooltip title="Add">
                      <IconButton color="primary" onClick={handleAddTiming}>
                        <AddCircleOutline />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={1} style={{ display: "flex", justifyContent: "center" }}>
                    {formic?.values?.timing.length > 1 && (
                      <Tooltip title="Remove">
                        <IconButton
                          color="secondary"
                          onClick={() => handleRemoveTiming(index)}
                        >
                          <RemoveCircleOutline />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Grid item xs={12}>
            <TextField
              label="Booking Link"
              name="bookingLinks"
              value={formic?.values?.bookingLinks}
              onChange={formic.handleChange}
              fullWidth
              variant="outlined"
              error={
                formic?.touched?.bookingLinks && Boolean(formic?.errors?.bookingLinks)
              }
              helperText={
                formic?.touched?.bookingLinks && (formic?.errors?.bookingLinks as string)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Contact Number"
              name="contactNo"
              value={formic?.values?.contactNo}
              onChange={formic.handleChange}
              fullWidth
              variant="outlined"
              error={
                formic?.touched?.contactNo && Boolean(formic?.errors?.contactNo)
              }
              helperText={
                formic?.touched?.contactNo && (formic?.errors?.contactNo as string)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Meta Title"
              name="metaTitle"
              value={formic?.values?.metaTitle}
              onChange={formic.handleChange}
              fullWidth
              variant="outlined"
              error={
                formic?.touched?.metaTitle && Boolean(formic?.errors?.metaTitle)
              }
              helperText={
                formic?.touched?.metaTitle && (formic?.errors?.metaTitle as string)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="text-area"
              label="Meta Description"
              name="metaDescription"
              value={formic?.values?.metaDescription}
              onChange={formic.handleChange}
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              error={
                formic?.touched?.metaDescription &&
                Boolean(formic?.errors?.metaDescription)
              }
              helperText={
                formic?.touched?.metaDescription &&
                (formic?.errors?.metaDescription as string)
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="text-area"
              label="Focus Keywords"
              name="focus"
              value={formic?.values?.focus}
              onChange={formic.handleChange}
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              error={formic?.touched?.focus && Boolean(formic?.errors?.focus)}
              helperText={
                formic?.touched?.focus && (formic?.errors?.focus as string)
              }
            />
          </Grid>
          {/* Submit button */}
          <Grid item xs={12}>
            <CustomButton type="submit" variant="contained" color="primary">
              Submit
            </CustomButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default CreateDeal;
