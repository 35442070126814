import React from "react";
import { TextField, Box, Grid, Typography } from "@mui/material";
import PincodeSelectDropDown from "./PincodeSelectDropDown";

export interface Address {
  address_1: string;
  address_2: string;
  pinCode: string;
  location: string;
  landmark: string;
}

interface AddressInputProps {
  address: Address;
  setAddress: React.Dispatch<React.SetStateAction<{ address: Address }>>;
  errors?: {
    address?: {
      address_1?: string;
      address_2?: string;
      pinCode?: string;
      location?: string;
      landmark?: string;
    };
  };
  setValues: React.Dispatch<React.SetStateAction<any>>;
  pincode: string;
  handlePincodeChange?: (value: string) => void;
}

const AddressInput: React.FC<AddressInputProps> = ({
  address,
  setAddress,
  errors,
  pincode,
  handlePincodeChange,
  setValues,
}) => {
  const handleAddressChange = (field: keyof Address, value: string) => {
    setAddress((prev) => ({
      ...prev,
      address: {
        ...prev?.address,
        [field]: value,
      },
    }));
  };

  const onPincodeChange = (selectedOption: any) => {
    const selectedPincode = selectedOption?.value || null;
    const selectedPincodeLable = selectedOption?.label || null;

    setAddress((prev) => ({
      ...prev,
      address: {
        ...prev?.address,
        pinCode: selectedPincodeLable,
      },
    }));

    setValues((prev: any) => ({
      ...prev,
      pincode: selectedPincodeLable,
      address: {
        ...prev?.address,
        pinCode: selectedPincode,
      },
    }));

    if (handlePincodeChange) {
      handlePincodeChange(selectedPincode);
    }
  };

  return (
    <Box>
      <Typography variant="subtitle1">Address:</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Address Line 1"
            value={address?.address_1}
            onChange={(e) => handleAddressChange("address_1", e.target.value)}
            fullWidth
            variant="outlined"
            error={!!errors?.address?.address_1}
            helperText={errors?.address?.address_1 || ""}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Address Line 2"
            value={address?.address_2}
            onChange={(e) => handleAddressChange("address_2", e.target.value)}
            fullWidth
            variant="outlined"
            error={!!errors?.address?.address_2}
            helperText={errors?.address?.address_2 || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PincodeSelectDropDown
            selectedPincode={address?.pinCode || null}
            handlePincodeChange={onPincodeChange}
            error={!!errors?.address?.pinCode}
            helperText={errors?.address?.pinCode}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Location"
            value={address?.location}
            onChange={(e) => handleAddressChange("location", e.target.value)}
            fullWidth
            variant="outlined"
            error={!!errors?.address?.location}
            helperText={errors?.address?.location || ""}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Landmark"
            value={address?.landmark}
            onChange={(e) => handleAddressChange("landmark", e.target.value)}
            fullWidth
            variant="outlined"
            error={!!errors?.address?.landmark}
            helperText={errors?.address?.landmark || ""}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddressInput;
