import React, { forwardRef, lazy, Suspense, useCallback, useEffect } from "react";
import ReactQuill, { ReactQuillProps } from "react-quill";
import "react-quill/dist/quill.snow.css";
// @ts-ignore
import ImageResize from "quill-image-resize-module-react";
import cn from "classnames";
import styles from "./richTextEditor.module.css";

type RichTextEditorProps = ReactQuillProps & {
  className?: string;
  value?: string;
  onChange?: (content: string) => void;
  error?: boolean;
  helperText?: string;
};

export type RichTextEditorRef = ReactQuill;
ReactQuill.Quill.register("modules/imageResize", ImageResize);
const LazyReactQuill = lazy(() => import("react-quill"));

const toolbarOptions = [
  [{ header: [1, 2, 3, 4, 5, 6] }],
  [{ size: ["small", false, "large", "huge"] }],
  ["bold", "italic", "underline", "strike"],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ["link", "image"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }],
  ["code-block"],
  ["clean"],
];

const formats = [
  "header",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "color",
  "background",
  "align",
  "link",
  "image",
  "list",
  "bullet",
  "indent",
  "code-block",
];

const RichTextEditor = forwardRef<RichTextEditorRef, RichTextEditorProps>(
  ({ className, value, onChange, error, helperText, ...props }, ref) => {
    const handleChange = useCallback(
      (content: string) => {
        const cleanedContent = content
          // .replace(/<p>(<br>|\s|&nbsp;)*<\/p>/g, "")
          // .replace(/<div>(<br>|\s|&nbsp;)*<\/div>/g, "")
          // .replace(/(&nbsp;|\s)+/g, " ")
          .trim();

        if (onChange) {
          onChange(cleanedContent);
        }
      },
      [onChange]
    );
    useEffect(() => {
      const style = document.createElement("style");
      style.innerHTML = `
        .ql-editor ol {
          counter-reset: item;
        }
        .ql-editor ol li {
          counter-increment: item;
        }
        .ql-editor ol li:before {
          content: counters(item, ".") ". ";
        }
        .ql-editor ol li ol {
          counter-reset: item;
        }
        .ql-editor ol li ol li:before {
          content: counters(item, ".", lower-alpha) ". ";
        }
      `;
      document.head.appendChild(style);

      return () => {
        document.head.removeChild(style);
      };
    }, []);

    return (
      <div className={cn(styles.richTextContainer)}>
        <Suspense fallback={<div>Loading...</div>}>
          <LazyReactQuill
            ref={ref}
            value={value}
            onChange={handleChange}
            modules={{
              toolbar: toolbarOptions,
              imageResize: { modules: ["Resize"] },
            }}
            formats={formats}
            className={cn(
              styles?.richTextContainer,
              className,
              "border border-secondary-300"
            )}
            theme="snow"
            {...props}
          />
        </Suspense>
        {error && helperText && (
          <div className={styles?.errorText}>{helperText}</div>
        )}
      </div>
    );
  }
);

RichTextEditor.displayName = "RichTextEditor";

export default RichTextEditor;
