import React from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  Paper,
  Divider,
} from "@mui/material";
import { LocationOn, Event, MonetizationOn } from "@mui/icons-material";
import { EventPageProps } from "./Events";
import backButton from "../../Images/backButton.svg";
import moment from "moment";

const EventPage: React.FC<EventPageProps> = ({ data, onClose }) => {
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={backButton}
          alt="backButton"
          height={25}
          width={25}
          style={{ marginRight: "12px", cursor: "pointer" }}
          onClick={onClose}
        />
        <Typography variant="h4" style={{ color: "#00C5B9" }}>
          Events
        </Typography>
      </div>

      <Container maxWidth="lg" sx={{ textAlign: "start", overflowY: "hidden" }}>
        <Box sx={{ my: 4 }}>
          <Paper elevation={3} sx={{ borderRadius: 2 }}>
            <Box sx={{ p: 4 }}>
              <Typography variant="h4" component="h1" gutterBottom>
                {data?.title}
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" gutterBottom>
                Event Details
              </Typography>
              <Grid container spacing={3}>
                {/* Left section - Event Date and Venue */}
                <Grid item xs={12} sm={6} md={4}>
                  <Box display="flex" alignItems="center">
                    <Event color="primary" />
                    <Typography variant="body1" sx={{ ml: 1 }}>
                      {moment(data?.startDate).format("D MMM, YYYY")}
                      {data?.startDate !== data?.endDate &&
                        ` - ${moment(data?.endDate).format("D MMM, YYYY")}`}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Box display="flex" alignItems="center">
                    <LocationOn color="primary" />
                    <Typography variant="body1" sx={{ ml: 1 }}>
                      {data?.venue}, {data?.pincode}
                    </Typography>
                  </Box>
                </Grid>

                {/* Right section - Price Details */}

                <Grid container spacing={2} item xs={12} sm={6} md={4}>
                  {data?.price?.map((item: any, index: number) => (
                    <Grid item xs={12} key={index} display={"flex"}>
                      <MonetizationOn color="primary" />
                      <Typography variant="body1">
                        {item.price} {item.priceTitle.toLowerCase()}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Divider sx={{ my: 2 }} />

              <Typography variant="h6" gutterBottom>
                About Event
              </Typography>
              <Grid container spacing={3}>
                {/* Left column for Event Info */}
                <Grid item xs={12} sm={6} md={6}>
                  <Typography variant="body1">
                    <strong>Event By:</strong> {data?.eventBy}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Timing:</strong> {data?.startTime} - {data?.endTime}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Show OnWeb:</strong> {data?.showOnWeb}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Source Type:</strong> {data?.sourceType}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Type:</strong> {data?.type}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Language:</strong> {data?.language}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Artist:</strong> {data?.artist}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Category:</strong> {data?.category}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Status:</strong> {data?.status}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Slug:</strong> {data?.slug}
                  </Typography>
                </Grid>

                {/* Right column for Event Details */}
                <Grid item xs={12} sm={6} md={6}>
                  <Typography variant="body1">
                    <strong>Verified By:</strong> {data?.verifiedBy}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Verification Date:</strong> {data?.verificationDate}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Reason:</strong> {data?.reason}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Booking Partner:</strong> {data?.bookingPartner}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Rank:</strong> {data?.rank}
                  </Typography>

                  <Typography variant="body1">
                    <strong>Meta Title:</strong> {data?.metaTitle}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Meta Description:</strong> {data?.metaDescription}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Promoted:</strong>
                    {data?.isPromoted ? "True" : "False"}
                  </Typography>
                </Grid>
              </Grid>

              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" gutterBottom>
                How To Get Here
              </Typography>
              <Typography variant="body1" paragraph>
                <strong>Address:</strong> {data?.venue}
                <br />
                <strong>Pincode:</strong> {data?.pincode}
              </Typography>

              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" gutterBottom>
                Contact Details
              </Typography>
              {data?.contactDetails?.map((contact, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                  <Typography variant="body1" paragraph>
                    <strong>Name:</strong> {contact?.name}
                    <br />
                    <strong>Mobile Number:</strong> {contact?.mobileNumber}
                  </Typography>
                </Box>
              ))}
            </Box>
            <img
              src={data?.desktopImage || "https://via.placeholder.com/1200x500"}
              alt={`${data?.title} event`}
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "8px 8px 0 0",
              }}
            />
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default EventPage;
