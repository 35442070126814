import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CustomButton from "src/components/CustomButton";
import CustomTextField from "src/components/CustomTextfield";
import CustomTypography from "src/components/CustomTypography";
import AuthHeader from "./AuthHeader";
import styles from "./SignIn.module.scss";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { SET_VERIFY_OTP } from "src/store/Action/AuthActions/SetUserAction";
import { toast } from "react-toastify";
import { ROUTES } from "src/constant";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";
import {
  getToken,
  navigateBasedOnPermissions,
  removeRole,
  removeToken,
  setToken,
} from "src/components/Common/Utils";
import Cookie from "js-cookie";
import { useMutation } from "@apollo/client";
import { SEND_OTP, VERIFY_OTP } from "src/graphql/mutations";
import CryptoJS from "crypto-js";
export const ENCRYPTED_SECRET_KEY = "X&O`Y?q43hjQ{v9@";
const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [otpSent, setOtpSent] = useState<Boolean>(false);
  const [timer, setTimer] = useState(120);
  const inputRefs = useRef<any>([]);
  const [verifyOtp, { loading: verifying }] = useMutation(VERIFY_OTP);
  const [sendOtp, { loading: sending }] = useMutation(SEND_OTP);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#1976d2",
      },
    },
    typography: {
      fontFamily: "Roboto, Arial, sans-serif",
    },
    components: {
      MuiContainer: {
        styleOverrides: {
          root: {
            paddingTop: "40px",
            paddingBottom: "40px",
          },
        },
      },
    },
  });

  // const handleSignInClick = async () => {
  //   setLoading(true);
  //   setError(null);
  //   try {
  //     const res = await dispatch(SET_USERS(email, password));

  //     if (res?.signInByAdmin?.statusCode === 200) {
  //       const accessToken = res.signInByAdmin?.accessToken;

  //       setToken(accessToken);
  //       // Set cookie with options
  //       Cookie.set("role", res?.signInByAdmin?.user?.role, {
  //         secure: true,
  //         sameSite: "Strict",
  //         expires: 1,
  //       });
  //       Cookie.set("accessToken", accessToken, {
  //         secure: true,
  //         sameSite: "Strict",
  //         expires: 1,
  //       });
  //       Cookie.set(
  //         "permissions",
  //         JSON.stringify(res?.signInByAdmin?.privacy?.permissions),
  //         {
  //           secure: true,
  //           sameSite: "Strict",
  //           expires: 1,
  //         }
  //       );
  //       Cookie.set("isEnvAdmin", res?.signInByAdmin?.isEnvAdmin, {
  //         secure: true,
  //         sameSite: "Strict",
  //         expires: 1,
  //       });

  //       if (!res?.signInByAdmin?.privacy?.permissions?.length) {
  //         removeToken();
  //         removeRole();
  //         throw new Error(
  //           "You don't have access of admin panel. Contact Admin"
  //         );
  //       }
  //       toast.success(res?.signInByAdmin?.message);
  //       navigateBasedOnPermissions(navigate, 1000);
  //     } else {
  //       throw new Error(res?.signInByAdmin?.message);
  //     }
  //   } catch (error: any) {
  //     toast.error(error?.message || "Error signing in");
  //     setError(error?.message || "An error occurred during sign in.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    let countdown: any;
    if (otpSent && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(countdown);
  }, [otpSent, timer]);

  const handleSendOtpClick = async () => {
    setLoading(true);
    setError(null);
    try {
      const res = await sendOtp({ variables: { email, otp: otp.join("") } });

      if (res?.data?.sendOTP?.status === 200) {
        toast.success(res?.data?.sendOTP?.message);
        setOtpSent(true);
        setTimer(120);
      } else {
        throw new Error(res?.data?.sendOTP?.message);
      }
    } catch (error: any) {
      toast.error(error?.message || "Error sending OTP");
      setError(error?.message || "An error occurred during sign-in.");
    } finally {
      setLoading(false);
    }
  };

  const handleOtpChange = (index: any, value: any) => {
    if (!/^[0-9]?$/.test(value)) return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < 3) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleOtpKeyDown = (index: any, e: any) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const res = await dispatch(SET_VERIFY_OTP(email, otp.join("")))
      if (res?.verifyOTP?.statusCode === 200) {

        const accessToken = res?.verifyOTP?.accessToken;
        setToken(accessToken);

        Cookie.set("role", res?.verifyOTP?.user?.role, {
          secure: true,
          sameSite: "Strict",
          expires: 1,
        });

        Cookie.set("accessToken", accessToken, {
          secure: true,
          sameSite: "Strict",
          expires: 1,
        });

        const encryptedPermissions = CryptoJS.AES.encrypt(
          JSON.stringify(res?.verifyOTP?.privacy?.permissions),
          ENCRYPTED_SECRET_KEY
        ).toString();
        
        localStorage.setItem("permissions", encryptedPermissions);

        Cookie.set("isEnvAdmin", res?.verifyOTP?.isEnvAdmin, {
          secure: true,
          sameSite: "Strict",
          expires: 1,
        });

        if (!res?.verifyOTP?.privacy?.permissions?.length) {
          removeToken();
          removeRole();
          throw new Error(
            "You don't have access of admin panel. Contact Admin"
          );
        }

        toast.success(res?.verifyOTP?.message);
        navigateBasedOnPermissions(navigate, 1000);
      }
      else {
        toast.error(res?.verifyOTP?.message);
      }
    } catch (error: any) {
      toast.error(error?.message || "Error verifying OTP");
    }
  };
  const getRoute: any = localStorage.getItem("permissionRoute");

  useEffect(() => {
    const token = getToken();
    if (token) {
      navigate(getRoute);
      // window.location.reload();
    }
  }, [navigate]);

  // const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box className={styles.formContainer}>
          <AuthHeader headerText="Welcome To Hi Surat" />
          <Box component="form" noValidate sx={{ mt: 1 }}>
            {!otpSent ? (
              <div className={styles.textfield}>
                <CustomTextField
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  variant="outlined"
                  placeholder="Enter your email"
                  fullWidth
                />
                {error && (
                  <FormHelperText error style={{ marginTop: "10px" }}>
                    {error}
                  </FormHelperText>
                )}
                <CustomButton
                  className={styles.signInBtnWrapper}
                  onClick={handleSendOtpClick}
                  disabled={sending}
                  fullWidth
                >
                  <CustomTypography variant="body2" className={styles.btnTextWrapper}>
                    {sending ? <CircularProgress size={20} color="inherit" /> : "Sign In With OTP"}
                  </CustomTypography>
                </CustomButton>
              </div>
            ) : (
              <>
                <div className={styles.textfield} style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "center" }}>
                  {otp.map((digit, index) => (
                    <CustomTextField
                      key={index}
                      value={digit}
                      onChange={(e) => handleOtpChange(index, e.target.value)}
                      onKeyDown={(e) => handleOtpKeyDown(index, e)}
                      variant="outlined"
                      InputProps={{ maxLength: 1, inputMode: "numeric", pattern: "[0-9]*", style: { textAlign: "center" } }}
                      style={{ width: "50px" }}
                      inputRef={(el: any) => (inputRefs.current[index] = el)}
                    />
                  ))}
                </div>
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                </div>
                <CustomButton
                  className={styles.signInBtnWrapper}
                  onClick={handleVerifyOtp}
                  disabled={otp.join("").length !== 4 || verifying || timer === 0}
                  fullWidth
                >
                  <CustomTypography variant="body2" className={styles.btnTextWrapper}
                    style={{
                      opacity: otp.join("").length !== 4 || verifying || timer === 0 ? 0.5 : 1, 
                      pointerEvents: "none",
                    }}
                  >
                    {verifying ? <CircularProgress size={20} color="inherit" /> : "Verify OTP"}
                  </CustomTypography>
                </CustomButton>
                {timer === 0 && (
                  <CustomButton
                    className={styles.signInBtnWrapper}
                    style={{ marginTop: "20px" }}
                    onClick={handleSendOtpClick}
                    fullWidth
                  >
                    <CustomTypography variant="body2" className={styles.btnTextWrapper}>
                    Resend OTP
                    </CustomTypography>
                  </CustomButton>
                )}
                {timer > 0 ? (
                  <CustomTypography variant="smallText" color="primary">OTP expires in {Math.floor(timer / 60)}:{(timer % 60).toString().padStart(2, "0")}</CustomTypography>
                ) : (
                  <CustomTypography variant="smallText" color="error">OTP expired. Please resend OTP.</CustomTypography>
                )}
              </>
            )}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default SignIn;
