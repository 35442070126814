import React, { useRef, useEffect } from "react";
import { QRCodeCanvas } from "qrcode.react";
import { Box } from "@mui/material";

interface QRCodeGeneratorProps {
  url: string;
  logoSrc?: string;
  style?: React.CSSProperties;
}

export const QRCodeGenerator: React.FC<QRCodeGeneratorProps> = ({ url, logoSrc, style }) => {
  const qrCodeRef = useRef<HTMLDivElement>(null);
  const fgColor = "#0891b2";

  useEffect(() => {
    if (qrCodeRef.current) {
      const canvas = qrCodeRef.current.querySelector("canvas");
      if (canvas) {
        qrCodeRef.current.dataset.url = canvas.toDataURL("image/png");
      }
    }
  }, [url]);

  return (
    <Box ref={qrCodeRef} style={{ position: "relative", display: "inline-block", ...style }}>
      <QRCodeCanvas
        value={url}
        fgColor={fgColor}
        size={256}
        level="H" 
      />
      {logoSrc && (
        <img
          src={logoSrc}
          alt="Logo"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40%", 
            height: "40%",
            borderRadius: "50%",
            background: "white",
            padding: "5px",
          }}
          draggable="false"
        />
      )}
    </Box>
  );
};
